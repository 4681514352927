import { Box, Grid, FormControl, Button, FormLabel, RadioGroup, FormControlLabel, Radio, Stack } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import WidgetBox from '../../../OfficeOnboardingWidgets/WidgetBox';
import { saveCallListLimits } from '../../AdminOnboardingService';
import {
    fetchUndeterminedOnboardingZipCodes,
    setPopError,
    setOpenPops,
    setZipError,
    setOpenZipCodes,
    fetchZipCodeGroups,
} from '../../../../store/adminOnboarding.slice';
import { ZipCodeGroups } from '../ZipCodeGroups/ZipCodeGroups';
import { copyObject } from '../../../../utils/common';
import MessageDisplay from '../../../Shared/MessageDisplay';
import { setShowMessage } from '../../../../store/shared.slice';
import PopulationBox from './PopulationBox';

import { CallListDistributionFilterTypeEnum } from '../../../../Enum/CallListDistributionFilterTypeEnum';
import { CallLimitParams } from '../../../../Models/AdminOnboarding/CallLimitParams.model';

const CallerListDistribution = () => {
    const dispatch = useDispatch();
    const { openPops, openZipCodes, selectedOnboarder, undeterminedZipCodes } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const { onboarderSettings } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const bothColumns = openPops.concat(openZipCodes);
    const allAssignedZipCodes = openZipCodes.map((group) => group.SelectedZipCodes).flat();
    const unassignedZipCodes = undeterminedZipCodes.filter((zip) => !allAssignedZipCodes.includes(zip));

    const [showZipCodeGroups, setShowZipCodeGroups] = useState<boolean>(false);

    const [filterType, setFilterType] = useState(CallListDistributionFilterTypeEnum.Population);

    const handleFilterTypeChange = (event: React.SyntheticEvent, newValue: string) => {
        setFilterType(parseInt(newValue));
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const closeZipCodeGroupsHandler = () => {
        setShowZipCodeGroups(false);
    };

    const handleChange = (event: any, popId: string) => {
        let { name, value } = event.target;
        const copyBothColumns = copyObject(bothColumns);
        const group = copyBothColumns.find((group) => group.PopulationId === popId);
        if (group) {
            group[name] = value ?? null;
        }
        const newPops = copyBothColumns;
        dispatch(setOpenPops(newPops.filter((group) => group.LimitType === CallListDistributionFilterTypeEnum.Population)));
        dispatch(setOpenZipCodes(newPops.filter((group) => group.LimitType === CallListDistributionFilterTypeEnum.ZipCodeGroup)));
    };

    const openZipCodeGroupsHandler = () => {
        setShowZipCodeGroups(true);
    };

    const handleSaveDistribution = async () => {
        let listLength = 0;
        if (!selectedOnboarder.CallListLength) {
            listLength = onboarderSettings.DefaultListLength ?? 10;
        } else {
            listLength = selectedOnboarder.CallListLength;
        }
        const popCount = getPopulationCount();
        const popExceeds = popCount > listLength;
        const zipCount = getZipCodesCount();
        const zipExceeds = zipCount > listLength;
        const callLimitParams = new CallLimitParams();
        callLimitParams.OnboarderStatusId = selectedOnboarder.Id!;

        if (filterType === CallListDistributionFilterTypeEnum.Population) {
            dispatch(setPopError(popExceeds));
            if (!popExceeds) {
                const popsWithLimits = openPops.filter((pop) => pop.Limit);
                callLimitParams.Limits = popsWithLimits;
                const response = await saveCallListLimits(callLimitParams);
                if (response.status === 200) {
                    dispatch(setShowMessage(true, 'Population Distribution Parameters Updated', 'success'));
                } else {
                    dispatch(setShowMessage(true, 'Error Updating Population Distribution Paramaters', 'error'));
                }
            } else {
                dispatch(setShowMessage(true, 'Total population count exceeds call list length', 'error'));
            }
        }
        if (filterType === CallListDistributionFilterTypeEnum.ZipCodeGroup) {
            dispatch(setZipError(zipExceeds));
            if (!zipExceeds) {
                const zipGroupsWithLimits = openZipCodes.filter((group) => group.Limit);
                callLimitParams.Limits = zipGroupsWithLimits;
                const response = await saveCallListLimits(callLimitParams);
                if (response.status === 200) {
                    dispatch(setShowMessage(true, 'Zip Code Distribution Parameters Updated', 'success'));
                } else {
                    dispatch(setShowMessage(true, 'Error Updating Zip Code Distribution Paramaters', 'error'));
                }
            } else {
                dispatch(setShowMessage(true, 'Total zip code groups count exceeds call list length', 'error'));
            }
        }
        if (filterType === CallListDistributionFilterTypeEnum.Both) {
            if (popCount + zipCount <= listLength) {
                const bothGroupsWithLimits = openZipCodes.concat(openPops).filter((group) => group.Limit);
                callLimitParams.Limits = bothGroupsWithLimits;
                const response = await saveCallListLimits(callLimitParams);
                if (response.status === 200) {
                    dispatch(setShowMessage(true, 'Both Distribution Parameters Updated', 'success'));
                } else {
                    dispatch(setShowMessage(true, 'Error Updating Both Distribution Paramaters', 'error'));
                }
                dispatch(setPopError(false));
                dispatch(setZipError(false));
            } else {
                dispatch(setPopError(true));
                dispatch(setZipError(true));
                dispatch(setShowMessage(true, 'Total combined count exceeds call list length', 'error'));
            }
        }
    };

    const getPopulationCount = () => {
        const totals = openPops.filter((p) => p.LimitType === CallListDistributionFilterTypeEnum.Population).map((pop) => pop.Limit);
        const totalCount = totals.length === 0 ? 0 : totals.reduce((a, b) => Number(a!) + Number(b!));
        return totalCount;
    };

    const getZipCodesCount = () => {
        const totals = openZipCodes.map((group) => group.Limit);
        const totalCount = totals.length === 0 ? 0 : totals.reduce((a, b) => Number(a!) + Number(b!));
        return totalCount;
    };

    useEffect(() => {
        let listLength = 10;
        if (!selectedOnboarder.CallListLength) {
            listLength = onboarderSettings?.DefaultListLength ?? 10;
        } else {
            listLength = selectedOnboarder.CallListLength;
        }

        const popCount = getPopulationCount();
        const zipCount = getZipCodesCount();

        if (filterType === CallListDistributionFilterTypeEnum.Population) {
            const popExceeds = popCount > listLength;
            dispatch(setPopError(popExceeds));
            dispatch(setZipError(false));
        }
        if (filterType === CallListDistributionFilterTypeEnum.ZipCodeGroup) {
            const zipExceeds = zipCount > listLength;
            dispatch(setZipError(zipExceeds));
            dispatch(setPopError(false));
        }
        if (filterType === CallListDistributionFilterTypeEnum.Both) {
            const bothCount = popCount + zipCount;
            const bothExceeds = bothCount > listLength;
            dispatch(setPopError(bothExceeds));
            dispatch(setZipError(bothExceeds));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, selectedOnboarder, openPops, openZipCodes]);

    useEffect(() => {
        dispatch(fetchZipCodeGroups());
        dispatch(fetchUndeterminedOnboardingZipCodes());
    }, [dispatch]);

    return (
        <>
            <ZipCodeGroups
                open={showZipCodeGroups}
                handleClose={closeZipCodeGroupsHandler}
                zipCodes={undeterminedZipCodes}
                MenuProps={MenuProps}
                unassignedZipCodes={unassignedZipCodes}
            />
            <WidgetBox>
                <MessageDisplay />

                <Box>
                    <Grid container spacing={2} p={2} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Choose Call List Distribution Filter:</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    row
                                    value={filterType}
                                    onChange={handleFilterTypeChange}
                                >
                                    <FormControlLabel value={CallListDistributionFilterTypeEnum.Population} control={<Radio />} label="By Population" />
                                    <FormControlLabel
                                        value={CallListDistributionFilterTypeEnum.ZipCodeGroup}
                                        control={<Radio />}
                                        label="By Zip Code Group Only"
                                    />
                                    <FormControlLabel value={CallListDistributionFilterTypeEnum.Both} control={<Radio />} label="By Both filters" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <Button variant="outlined" color="primary" sx={{ alignSelf: 'flex-end' }} onClick={openZipCodeGroupsHandler}>
                                    View Zip Code Groups
                                </Button>
                                <Button sx={{ minWidth: '120px' }} variant="contained" onClick={handleSaveDistribution}>
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container alignItems="flex-start" justifyContent="space-evenly" spacing={2}>
                    {filterType === CallListDistributionFilterTypeEnum.Population &&
                        openPops.map((group, index) => (
                            <PopulationBox key={group.PopulationName + '_' + index} pop={group} index={index} handleChange={handleChange} />
                        ))}
                    {filterType === CallListDistributionFilterTypeEnum.ZipCodeGroup &&
                        openZipCodes.map((group, index) => (
                            <PopulationBox key={group.PopulationName + '_' + index} pop={group} index={index} handleChange={handleChange} />
                        ))}
                    {filterType === CallListDistributionFilterTypeEnum.Both &&
                        openPops
                            .concat(openZipCodes)
                            .map((group, index) => (
                                <PopulationBox key={group.PopulationName + '_' + index} pop={group} index={index} handleChange={handleChange} />
                            ))}
                </Grid>
            </WidgetBox>
        </>
    );
};

export default CallerListDistribution;
