export enum OnboarderStatusTabValueEnum {
    CallingPerformanceDescription = 'calling-performance',
    CallingPerformance = 1,
    CallListDistributionDescription = 'call-list-distribution',
    CallListDistribution = 2,
    CurrentCallListDescription = 'current-call-list',
    CurrentCallList = 3,
    CallHistoryDescription = 'call-history',
    CallHistory = 4,
}
