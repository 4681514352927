import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, ERROR } from '../utils/constants';
import axios from 'axios';
import ITeamOptimizerResult from '../Models/Teams/TeamOptimizerResult.model';
import { setShowMessage, setIsLoading } from './shared.slice';

const intialOptimizedTeamResults: ITeamOptimizerResult[] = [];

const teamsSlice = createSlice({
    name: 'teams',
    initialState: {
        isFetching: false,
        includeUnassignedMembers: true,
        npAndBhTeams: [],
        optimizedTeamResults: intialOptimizedTeamResults,
        teams: [],
        teamsByProviderId: '',
        selectedTeamIds: [],
        selectedMembers: [],
    },
    reducers: {
        _setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _setIncludeUnassignedMembers(state, action) {
            state.includeUnassignedMembers = action.payload;
        },
        _setNpOrBhTeams(state, action) {
            state.npAndBhTeams = action.payload;
        },
        _setOptimizedTeamResults(state, action) {
            state.optimizedTeamResults = action.payload;
        },
        _setTeams(state, action) {
            state.teams = action.payload;
        },
        _setTeamsByProviderId(state, action) {
            state.teamsByProviderId = action.payload;
        },
        _setSelectedTeamIds(state, action) {
            state.selectedTeamIds = action.payload;
        },
        _setSelectedMembers(state, action) {
            state.selectedMembers = action.payload;
        },
    },
});

const {
    _setIsFetching,
    _setIncludeUnassignedMembers,
    _setNpOrBhTeams,
    _setOptimizedTeamResults,
    _setTeams,
    _setTeamsByProviderId,
    _setSelectedMembers,
    _setSelectedTeamIds,
} = teamsSlice.actions;

export const assignToTeam =
    (teamId: string, members: any[], postSaveHandle: Function = null): AppThunk =>
    async (dispatch) => {
        try {
            await axios.put(`${API_URL}/team/assignMemberToTeam?teamId=${teamId}`, members);
            if (postSaveHandle) {
                postSaveHandle();
            }
        } catch (error) {
            console.log(error);
            dispatch(setShowMessage(true, 'Error assigning to team.', ERROR));
        }
    };

export const isFetching =
    (fetching: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsFetching(fetching));
    };

export const fetchTeams = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getallteams`);
    dispatch(_setTeams(response.data));
};

export const fetchTeamsName = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getteamsname`);
    dispatch(_setTeams(response.data));
};

export const fetchOptimizedTeamResults = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const memberIds = getState()
        .teams.selectedMembers.map((member) => member.Id)
        .join(',');
    try {
        const response = await axios.get(
            `${API_URL}/team/getoptimizedteamresults?includeUnassignedMembers=${getState().teams.includeUnassignedMembers}&selectedTeamIds=${
                getState().teams.selectedTeamIds
            }&memberIds=${memberIds}`
        );
        dispatch(_setOptimizedTeamResults(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        dispatch(setShowMessage(true, error.response.data, ERROR));
        dispatch(setIsLoading(false));
    }
};

export const fetchTeamsByProviderId =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/team/getteamsbyproviderid?providerId=${providerId}`);
        dispatch(_setTeamsByProviderId(response.data[0]?.Id || ''));
    };

export const fetchNpOrBhTeams = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getbhandnpteam`);
    dispatch(_setNpOrBhTeams(response.data));
};

export const saveMaxTeamSize =
    (maxTeamSize: number, teamId: string): AppThunk =>
    async (dispatch) => {
        await axios.put(`${API_URL}/team/savemaxteamsize?teamId=${teamId}&maxTeamSize=${maxTeamSize}`);
        dispatch(fetchOptimizedTeamResults());
    };

export const setIncludeUnassignedMembers =
    (includeUnassignedMembers: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIncludeUnassignedMembers(includeUnassignedMembers));
    };

export const setSelectedTeamIds =
    (selectedTeamIds: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedTeamIds(selectedTeamIds));
    };

export const setSelectedMembers =
    (selectedMembers: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedMembers(selectedMembers));
    };

export const setOptimizedTeamResults =
    (optimizedTeamResults: ITeamOptimizerResult[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setOptimizedTeamResults(optimizedTeamResults));
    };

export const setTeams =
    (teams: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setTeams(teams));
    };

export const clearResults = (): AppThunk => async (dispatch) => {
    dispatch(_setOptimizedTeamResults([]));
};

export default teamsSlice.reducer;
