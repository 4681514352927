import { OpenPopulationDto } from './OpenPopulationDto.model';

export default interface ICallLimitParams {
    AssignmentFrequency: number;
    Limits: OpenPopulationDto[];
    MaxAssignments: number;
    OnboarderStatusId: string;
}

export class CallLimitParams implements ICallLimitParams {
    AssignmentFrequency: number;
    Limits: OpenPopulationDto[];
    MaxAssignments: number;
    OnboarderStatusId: string;

    constructor() {
        this.AssignmentFrequency = 0;
        this.Limits = [];
        this.MaxAssignments = 0;
        this.OnboarderStatusId = '';
    }
}
