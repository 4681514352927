import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import MemberSearch from '../../Shared/MemberSearch';
import { useDispatch } from 'react-redux';

const TeamOptimizerSelectMember = (props) => {
    const dispatch = useDispatch();

    const listOfSelectedMembers = (arr) => {
        const listOfSelectedMembers = arr.map((el) => props.allMembers.find((member) => member.Id === el));
        const selected = listOfSelectedMembers.map((el) => ({ ...el }));
        dispatch(props.setSelectedMembers(selected));
        props.onClose();
    };

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 200,
        },
        {
            field: 'RiskLevel',
            headerName: 'RiskLevel',
            minWidth: 100,
        },
        {
            field: 'RiskScore',
            headerName: 'RiskScore',
            minWidth: 100,
        },
        {
            field: 'TeamName',
            headerName: 'Team',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Stack>
                            {params.row.TeamName?.split(',').map((item, key) => {
                                return <label key={key}>{item}</label>;
                            })}
                        </Stack>
                    </>
                );
            },
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 200,
            flex: 1,
        },
    ];

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
                <DialogTitle>Add Members</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} paddingTop={2}>
                        <Grid item xs={4}>
                            <MemberSearch
                                rowData={props.memberSearch.rowData}
                                allMembers={props.memberSearch.allMembers}
                                setRowData={props.memberSearch.setRowData}
                                memberSearchValue={props.memberSearch.memberSearchValue}
                                setMemberSearchValue={props.memberSearch.setMemberSearchValue}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ height: '64vh', width: '100%' }}>
                                <DataGrid
                                    rows={props.rowData}
                                    columns={columns}
                                    pageSize={props.pageSize}
                                    checkboxSelection
                                    selectionModel={props.selectionModel}
                                    onSelectionModelChange={props.onSelectionModelChange}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={props.pageSizeHandler}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        [`& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root`]: {
                                            display: 'none',
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className="button-120"
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => listOfSelectedMembers(props.selectionModel)}
                    >
                        Select Members
                    </Button>
                    <Button className="button-120" variant="outlined" onClick={props.onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TeamOptimizerSelectMember;
