import BaseEntity from '../BaseEntity.model';
import { Diagnosis } from '../DocumentEngagements/Diagnosis.model';
import { Team } from './Team.model';

export default interface IMemberBasicInfo extends BaseEntity {
    Name: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    PhoneNumber: string;
    PrimaryLanguage: string;
    AhcccsId: string;
    Mem_AHCCCS_ID: string;
    Gender: string;
    Age: number;
    DOB: string;
    IsInTransition: boolean;
    IsInHra: boolean;
    IsInFuhFum: boolean;
    Diagnosis: Diagnosis[];
    FullAddress: string;
    Team: Team;
}

export class MemberBasicInfo implements IMemberBasicInfo {
    Id: string;
    Name: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    PhoneNumber: string;
    PrimaryLanguage: string;
    AhcccsId: string;
    Mem_AHCCCS_ID: string;
    Gender: string;
    Age: number;
    DOB: string;
    IsInTransition: boolean;
    IsInHra: boolean;
    Status: string;
    NoKnownAllergies: boolean;
    IsInFuhFum: boolean;
    Diagnosis: Diagnosis[];
    FullAddress: string;
    Team: Team;

    constructor() {
        this.Id = '';
        this.Name = '';
        this.Address = '';
        this.State = '';
        this.Country = '';
        this.ZipCode = '';
    }
}
