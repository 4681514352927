import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Loading from '../../Shared/Loading';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material';
import Authorization from '../../../utils/Authorization';
import {
    assignToTeam,
    clearResults,
    fetchOptimizedTeamResults,
    fetchTeams,
    setIncludeUnassignedMembers,
    setOptimizedTeamResults,
    setSelectedMembers,
    setSelectedTeamIds,
    setTeams,
} from '../../../store/teams.slice';
import TeamKey from './TeamKey';
import TeamOptimizerMap from '../../Maps/TeamOptimizerMap';
import { MemberBasicInfo } from '../../../Models/Member/MemberBasicInfo.model';
import TeamOptimizerSelectMember from './TeamOptimizerSelectMember';
import { GridSelectionModel } from '@mui/x-data-grid';
import { getMembers } from '../../../store/adminMembers.slice';
import { fetchActiveStatusMembers } from '../../../store/members.slice';
import { setShowMessage } from '../../../store/shared.slice';
import { ERROR, SUCCESS } from '../../../utils/constants';

const classes = {
    addressRow: {
        borderBottom: '1px solid #ddd',
        marginBottom: '8px',
    },
};

const TeamOptimizer = () => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const currentProviderId: string = authData.UserId;
    const { includeUnassignedMembers, optimizedTeamResults, selectedTeamIds, selectedMembers, teams } = useSelector((state: RootState) => state.teams);
    const { members } = useSelector((state: RootState) => state.members);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [selectedLat, SetSelectedLat] = useState<number>(0);
    const [selectedLon, SetSelectedLon] = useState<number>(0);
    const [selectedMember, SetSelectedMember] = useState<MemberBasicInfo>(null);
    const [selectedTeamId, SetSelectedTeamId] = useState<string>('');
    const [openAddMember, setOpenAddMember] = useState(false);
    const [status, setStatus] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const [memberSearchValue, setMemberSearchValue] = useState('');
    const [pageSize, setPageSize] = useState(15);

    const pageSizeHandler = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const memberSearch = {
        rowData: rowData,
        allMembers: allMembers,
        setRowData: setRowData,
        memberSearchValue: memberSearchValue,
        setMemberSearchValue: setMemberSearchValue,
    };

    const selectSpecificMemberHandler = () => {
        setOpenAddMember(true);
    };

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const onSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const closeAddMemberHandler = () => {
        setOpenAddMember(false);
    };

    const getAllActiveStatusMembers = async () => {
        dispatch(setSelectedMembers([]));
        setRowData([]);
        dispatch(fetchActiveStatusMembers());
    };

    const handleSelectedTeamsChange = (event: SelectChangeEvent<typeof selectedTeamIds>) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        var arrayOfIds = typeof value === 'string' ? value.split(',') : value;
        if (arrayOfIds.length <= 7) {
            dispatch(setSelectedTeamIds(arrayOfIds));
        } else {
            dispatch(setShowMessage(true, 'You can only select up to 7 teams.', ERROR));
        }
    };

    const assignToTeamClick = () => {
        if (selectedMember && selectedTeamId) {
            var member = { Id: selectedMember.Id, TeamId: selectedTeamId };
            dispatch(
                assignToTeam(selectedTeamId, [member], () => {
                    const copyTeams = JSON.parse(JSON.stringify(teams));
                    const updatedTeams = copyTeams.map((team) => {
                        if (team.Id === selectedTeamId) {
                            team.MemberCount++;
                        }
                        return team;
                    });
                    dispatch(setTeams(updatedTeams));

                    const copyOptimizedTeamResults = JSON.parse(JSON.stringify(optimizedTeamResults));
                    const updatedOptimizedTeamResults = copyOptimizedTeamResults.map((teamResult) => {
                        if (teamResult.Team.Id === selectedTeamId) {
                            teamResult.Team.MemberCount++;
                        }

                        teamResult.MemberResults = teamResult.MemberResults.filter((x) => {
                            return x.Member.Id !== selectedMember.Id;
                        });
                        return teamResult;
                    });
                    dispatch(setOptimizedTeamResults(updatedOptimizedTeamResults));

                    SetSelectedMember(null);
                    dispatch(setShowMessage(true, 'Team assignment successful.', SUCCESS));
                })
            );
        } else {
            dispatch(setShowMessage(true, 'Please select a team and member for team assignment.', ERROR));
        }
    };

    const valid = () => {
        if (selectedTeamIds && selectedTeamIds.length === 0) {
            dispatch(setShowMessage(true, 'Please select at least one team.', ERROR));
            return false;
        }

        if (selectedMembers.length === 0 && !includeUnassignedMembers) {
            dispatch(setShowMessage(true, 'Please select members or check active members not on a team.', ERROR));
            return false;
        }

        return true;
    };

    const searchTeamOptimization = () => {
        if (valid()) {
            dispatch(fetchOptimizedTeamResults());
        }
    };

    useEffect(() => {
        dispatch(fetchTeams());
        getAllActiveStatusMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProviderId, activeProviders]);

    useEffect(() => {
        if (members.length > 0) {
            setRowData(members);
            setAllMembers(members);
        }
    }, [members]);

    return (
        <div id="mapContainer" className="mt-2">
            {<Loading message={'Calculating, please be patient...'} progress={true} />}
            <Grid style={{ padding: '16px' }} container spacing={1}>
                <Grid item md={3} style={{ borderRight: 'solid', borderRightWidth: '2px', paddingRight: '8px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="plan-type-label">Select Teams (Member Count/Max Size)</InputLabel>
                        <Select
                            labelId="plan-type-label"
                            id="plan-type"
                            multiple
                            value={selectedTeamIds}
                            margin="dense"
                            label="Select Teams (Member Count/Max Size)"
                            onChange={handleSelectedTeamsChange}
                            input={<OutlinedInput label="Select Teams (Member Count/Max Size)" />}
                            renderValue={(selected) =>
                                teams
                                    .filter((x) => selected.includes(x.Id))
                                    .map((x) => x.Name)
                                    .join(' | ')
                            }
                        >
                            {teams
                                .filter((x) => x.IsActive)
                                ?.map((team) => {
                                    return (
                                        <MenuItem key={team.Id} value={team.Id} disabled={team.MemberCount >= team.MaxTeamSize}>
                                            <Checkbox checked={selectedTeamIds.includes(team.Id)} />
                                            <ListItemText primary={`${team.Name} (${team.MemberCount}/${team.MaxTeamSize})`} />
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        sx={{ margin: 0, marginTop: '8px' }}
                        label="Include active members not on a team"
                        control={
                            <Checkbox
                                checked={includeUnassignedMembers}
                                size="small"
                                name="Include active members not on a team"
                                onChange={(e) => {
                                    dispatch(setIncludeUnassignedMembers(e.target.checked));
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item md={6}>
                    <Button variant="contained" onClick={selectSpecificMemberHandler}>
                        Select Specific Members
                    </Button>
                    <Stack direction="row" spacing={3} style={{ paddingTop: '8px' }}>
                        {selectedMembers.map((member) => {
                            return (
                                <Typography key={member.Id}>
                                    {member.LastName}, {member.FirstName}
                                </Typography>
                            );
                        })}
                    </Stack>
                    <TeamOptimizerSelectMember
                        open={openAddMember}
                        onClose={closeAddMemberHandler}
                        pageSize={pageSize}
                        status={status}
                        memberSearch={memberSearch}
                        setStatus={setStatus}
                        pageSizeHandler={pageSizeHandler}
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        allMembers={allMembers}
                        rowData={rowData}
                        getMembers={getMembers}
                        setSelectedMembers={setSelectedMembers}
                    />
                </Grid>
                <Grid item md={3}>
                    <Grid container justifyContent={'flex-end'}>
                        <Button variant="contained" onClick={searchTeamOptimization}>
                            Search
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            variant="outlined"
                            onClick={() => {
                                dispatch(clearResults());
                            }}
                        >
                            Clear Results
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            {optimizedTeamResults?.length === 0 ? (
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '50vh' }}>
                    <Grid item xs={3}>
                        <Typography variant="h6">No Results Found</Typography>
                        <br />
                        <Typography variant="h6">1. Select teams for optimization</Typography>
                        <Typography variant="h6">2. (Optional) adjust member search criteria</Typography>
                        <Typography variant="h6">3. Click Search</Typography>
                    </Grid>
                </Grid>
            ) : (
                <Box>
                    <Grid style={{ padding: '16px' }} container spacing={1}>
                        <Grid item md={10}>
                            <Grid container>
                                {optimizedTeamResults?.map((teamResult, indexTeam) => {
                                    return (
                                        <Grid item>
                                            <TeamKey team={teamResult.Team} index={indexTeam} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item md={2}>
                            {selectedMember?.Id && <Typography variant="h6">Selected: {selectedMember?.Name}</Typography>}
                            <Stack direction="row" spacing={1} style={{ paddingTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="plan-type-label">Assign to Team</InputLabel>
                                    <Select
                                        labelId="plan-type-label"
                                        id="plan-type"
                                        value={selectedTeamId}
                                        margin="dense"
                                        label="Assign to Team"
                                        onChange={(e) => SetSelectedTeamId(e.target.value)}
                                    >
                                        {optimizedTeamResults?.map((teamResult) => {
                                            return (
                                                <MenuItem value={teamResult.Team.Id} key={teamResult.Team.Id}>
                                                    {teamResult.Team.Name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <Button variant="contained" onClick={assignToTeamClick}>
                                    Assign
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid style={{ padding: '16px' }} container spacing={1}>
                        <Grid item md={5} container>
                            <Grid item md={12} container>
                                <Grid item md={2}>
                                    <Typography style={{ fontWeight: 'bold' }}>AhcccsId</Typography>
                                </Grid>
                                <Grid item md={2}>
                                    <Typography style={{ fontWeight: 'bold' }}>Member Name</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography style={{ fontWeight: 'bold' }}>Address</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography style={{ fontWeight: 'bold' }}>Recommended Team</Typography>
                                </Grid>
                                <Grid item md={2}>
                                    <Typography style={{ fontWeight: 'bold' }}>Current Team</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={7}></Grid>
                    </Grid>
                    <Divider />
                    <Grid style={{ padding: '16px' }} container spacing={1}>
                        <Grid item md={5} container style={{ height: '50px' }}>
                            {optimizedTeamResults?.map((result, index) => {
                                return result.MemberResults.map((memberResult, memberIndex) => {
                                    return (
                                        <Grid
                                            key={memberIndex}
                                            item
                                            md={12}
                                            container
                                            sx={classes.addressRow}
                                            onClick={() => {
                                                SetSelectedMember(memberResult.Member);
                                                SetSelectedLat(memberResult.Lat);
                                                SetSelectedLon(memberResult.Lon);
                                                SetSelectedTeamId(result.Team.Id);
                                            }}
                                            className={memberResult.Member.Id === selectedMember?.Id ? 'highlight-member' : ''}
                                        >
                                            <Grid item md={2}>
                                                <Typography>{memberResult.Member.AhcccsId}</Typography>
                                            </Grid>
                                            <Grid item md={2}>
                                                <Typography>{memberResult.Member.Name}</Typography>
                                            </Grid>
                                            <Grid item md={3}>
                                                <Typography>{memberResult.Member.FullAddress}</Typography>
                                            </Grid>
                                            <Grid item md={3} style={{ textAlign: 'center' }}>
                                                <Typography> {result.Team.Name}</Typography>
                                            </Grid>
                                            <Grid item md={2} style={{ textAlign: 'center' }}>
                                                <Typography> {memberResult.Member.Team?.Name}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                });
                            })}
                        </Grid>
                        <Grid item md={7}>
                            <Grid item>
                                <TeamOptimizerMap selectedLat={selectedLat} selectedLon={selectedLon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
};

export default TeamOptimizer;
