import { CallListDistributionFilterTypeEnum } from '../../Enum/CallListDistributionFilterTypeEnum';
import BaseEntity from '../BaseEntity.model';
import IndividualOnboardingZipCode from './IndividualOnboardingZipCode';

export default interface IOpenPopulationDto extends BaseEntity {
    FuhUndCount: number;
    PopulationId: string;
    PopulationName: string;
    PopulationNumber?: number;
    Limit?: number;
    LimitType: CallListDistributionFilterTypeEnum;
    OpenMemberCount?: number;
    PopulationZipCodes?: IndividualOnboardingZipCode[];
    ScheduledMemberCount?: number;
    SelectedZipCodes?: string[];
    ZipCodeGroupId?: string;
    ZipCodeGroupsList?: string[];
}

export class OpenPopulationDto implements IOpenPopulationDto {
    Id: string;
    FuhUndCount: number;
    PopulationId: string;
    PopulationName: string;
    PopulationNumber?: number;
    Limit?: number;
    LimitType: CallListDistributionFilterTypeEnum;
    OpenMemberCount?: number;
    PopulationZipCodes?: IndividualOnboardingZipCode[];
    ScheduledMemberCount?: number;
    SelectedZipCodes?: string[];
    ZipCodeGroupId?: string;
    ZipCodeGroupsList?: string[];

    constructor() {
        this.Id = '';
        this.FuhUndCount = 0;
        this.PopulationId = '';
        this.PopulationName = '';
        this.PopulationNumber = 0;
        this.Limit = 0;
        this.LimitType = CallListDistributionFilterTypeEnum.Population;
        this.OpenMemberCount = 0;
        this.PopulationZipCodes = [];
        this.ScheduledMemberCount = 0;
        this.SelectedZipCodes = [];
        this.ZipCodeGroupId = '';
        this.ZipCodeGroupsList = [];
    }
}
