import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { TimePeriodFilterEnum } from '../Enum/TimePeriodFilterEnum';

const memberAccessReport = createSlice({
    name: 'memberAccessReport',
    initialState: {
        memberAccessData: [],
    },
    reducers: {
        _setMemberAccessData(state, action) {
            state.memberAccessData = action.payload;
        },
    },
});

const { _setMemberAccessData } = memberAccessReport.actions;

export const fetchMemberAccessReport =
    (searchKey: string, dateFilter: TimePeriodFilterEnum, start: string, end: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberAccessData([]));
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/memberaccessreport/getmemberaccessreport?searchKey=${searchKey}&dateFilter=${dateFilter}&start=${start}&end=${end}`
        );
        dispatch(setIsLoading(false));
        dispatch(_setMemberAccessData([]));
        dispatch(_setMemberAccessData(response.data));
    };

export const exportMemberAccessReport =
    (searchKey: string, dateFilter: TimePeriodFilterEnum, start: string, end: string): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/memberaccessreport/exportmemberaccessreport?searchKey=${searchKey}&dateFilter=${dateFilter}&start=${start}&end=${end}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberAccessReport.reducer;
