import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    closeAddToCallListHandler,
    getSingleCallItem,
    setAddToCallListValues,
    setCallItem,
    setCallListMemberIsValid,
    setOnboarderStatus,
    setSelectedPhone,
    updateCallItem,
} from '../../../store/onboardingWorkflow.slice';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import MessageDisplay from '../../Shared/MessageDisplay';
import { setShowMessage } from '../../../store/shared.slice';
import moment from 'moment';
import CallItem from '../../../Models/OfficeOnboarding/CallItem.model';
import { copyObject } from '../../../utils/common';

const AddToListModal = () => {
    const { addToCallListIsOpen, addToCallListResults, addToCallListValues, allOnboarders, callListMemberIsValid, onboarderStatus } = useSelector(
        (state: RootState) => state.onboardingWorkflowSlice
    );

    const dispatch = useDispatch();

    const checkIfFormIsValid = () => {
        if (
            (addToCallListValues.LastName.length > 0 && addToCallListValues.DateOfBirth) ||
            (addToCallListValues.FirstName.length > 0 && addToCallListValues.DateOfBirth) ||
            addToCallListValues.AHCCCSID.length > 0
        ) {
            dispatch(getSingleCallItem(addToCallListValues));
        } else {
            dispatch(setCallListMemberIsValid(false));
        }
    };

    const modalSize = addToCallListResults ? 'lg' : 'sm';

    const columns: GridColDef[] = [
        {
            field: 'AHCCCSID',
            headerName: 'AHCCCS ID',
            minWidth: 160,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DateOfBirth',
            headerName: 'Date of Birth',
            minWidth: 160,
        },
        {
            field: 'Eligibility',
            headerName: 'Eligibility',
            minWidth: 160,
        },
        {
            field: 'MemberStatus',
            headerName: 'Status',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${MemberStatusEnum[params.row.MemberStatus] || ''}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 200,
        },
        {
            field: 'PopulationName',
            headerName: 'Population Name',
            minWidth: 200,
        },
    ];

    const addToCallList = (member: CallItem) => {
        if (onboarderStatus.CallList?.find((x) => x.Id === member.Id)) {
            dispatch(setShowMessage(true, 'Member is already in current call list', 'error'));
            return;
        }
        const memberCopy = copyObject(member);

        memberCopy.AssignedOnboarderId = onboarderStatus.Id;

        memberCopy.ExpirationDate = moment().add(1, 'days').format();
        memberCopy.TimesAssigned++;
        memberCopy.TimesAssignedToday++;
        memberCopy.LastAssignmentDate = moment().utc().format();
        memberCopy.LastChpAssignmentDateString = moment().format('MM/DD/YYYY HH:mm:ss A');

        dispatch(updateCallItem(memberCopy));
        const onboarderStatusCopy = { ...onboarderStatus, CallList: [memberCopy, ...onboarderStatus.CallList] };
        dispatch(setOnboarderStatus(onboarderStatusCopy));
        dispatch(setCallItem(memberCopy));
        dispatch(setSelectedPhone(memberCopy.Phones?.length ? memberCopy.Phones[0] : null));
        dispatch(closeAddToCallListHandler());
        dispatch(setShowMessage(true, 'Member added to call list', 'success'));
    };

    const getCallItemAvailablility = (member: CallItem) => {
        const assignedOnboarder = allOnboarders.find((x) => x.Id === member.AssignedOnboarderId);
        if (!member.AssignedOnboarderId || member.AssignedOnboarderId === onboarderStatus.Id) {
            addToCallList(member);
        }
        if (member.AssignedOnboarderId && member.AssignedOnboarderId !== onboarderStatus.Id) {
            dispatch(setShowMessage(true, `Member is already assigned to an onboarder - ${assignedOnboarder?.Username}`, 'error'));
            return;
        }
    };

    return (
        <>
            <MessageDisplay />
            <Dialog open={addToCallListIsOpen} onClose={() => dispatch(closeAddToCallListHandler())} fullWidth maxWidth={modalSize}>
                <DialogTitle>Add to call list</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <Stack spacing={2} paddingY={2}>
                            <TextField
                                autoComplete="off"
                                label="Last name"
                                variant="outlined"
                                value={addToCallListValues.LastName}
                                onChange={(e) => dispatch(setAddToCallListValues({ ...addToCallListValues, LastName: e.target.value }))}
                            />
                            <TextField
                                autoComplete="off"
                                label="First name"
                                variant="outlined"
                                value={addToCallListValues.FirstName}
                                onChange={(e) => dispatch(setAddToCallListValues({ ...addToCallListValues, FirstName: e.target.value }))}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Date of Birth"
                                    onChange={(newValue: Date) => dispatch(setAddToCallListValues({ ...addToCallListValues, DateOfBirth: newValue }))}
                                    value={addToCallListValues.DateOfBirth}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            autoComplete="off"
                                            required
                                            error={!callListMemberIsValid && addToCallListValues.DateOfBirth === null}
                                            helperText={
                                                !callListMemberIsValid && addToCallListValues.DateOfBirth === null
                                                    ? 'Search by Date of Birth and First/Last Name or AHCCCS ID'
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                or
                            </Typography>
                            <TextField
                                autoComplete="off"
                                label="AHCCCS ID"
                                variant="outlined"
                                required
                                value={addToCallListValues.AHCCCSID}
                                error={!callListMemberIsValid && addToCallListValues.AHCCCSID === ''}
                                helperText={
                                    !callListMemberIsValid && addToCallListValues.AHCCCSID === ''
                                        ? 'Search by Date of Birth and First/Last Name or AHCCCS ID'
                                        : ''
                                }
                                onChange={(e) => dispatch(setAddToCallListValues({ ...addToCallListValues, AHCCCSID: e.target.value }))}
                            />
                        </Stack>
                    </FormGroup>
                    {addToCallListResults && (
                        <>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Search results
                            </Typography>
                            <div style={{ height: '30vh', width: '100%' }}>
                                <DataGrid
                                    rows={addToCallListResults || []}
                                    columns={columns}
                                    pageSize={3}
                                    rowsPerPageOptions={[]}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    onRowClick={(params) => {
                                        getCallItemAvailablility(params.row);
                                    }}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                    localeText={{
                                        noRowsLabel:
                                            'No results found. Please check that the member you are trying to add has an UNDETERMINED status and is in an Active Population.',
                                    }}
                                />
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={() => dispatch(closeAddToCallListHandler())}>
                        Cancel
                    </Button>
                    <Button variant="contained" sx={{ minWidth: '120px' }} color="primary" onClick={() => checkIfFormIsValid()}>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddToListModal;
