import React, { useEffect, useState, useRef } from 'react';
import { Paper, TextField, IconButton, Tooltip, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parentUrl } from '../../../../utils/constants';
import { generateRandomId } from '../../../../utils/common';
import '../../Widgets.css';

import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { fetchFuhFumVisitsWidget, fetchFuhFumReadmissionsWidget } from '../../../Widgets/WidgetService';
import { DataGrid, GridCellParams, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { isDefaultDate } from '../../../../utils/common';
import { dateFormatter } from '../../../../utils/timeFormat';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FuhFumVisitsReadmissions = () => {
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [visits, setVisits] = useState([]);
    const [readmissions, setReadmissions] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const {
        fuhfumVisitReadmissionDateFilter,
        setFuhfumVisitReadmissionDateFilter,
        fuhfumVisitReadmissionFromDate,
        setFuhfumVisitReadmissionFromDate,
        fuhfumVisitReadmissionToDate,
        setFuhfumVisitReadmissionToDate,
        fuhfumVisitReadmissionTab,
        setFuhfumVisitReadmissionTab,
    } = useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        if (
            fuhfumVisitReadmissionDateFilter !== WidgetTimeFilterEnum.Custom ||
            (Boolean(fuhfumVisitReadmissionFromDate) && Boolean(fuhfumVisitReadmissionToDate))
        ) {
            setIsFetched(false);
            const changeFilter = async () => {
                let dateFrom =
                    Boolean(fuhfumVisitReadmissionFromDate) && fuhfumVisitReadmissionDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fuhfumVisitReadmissionFromDate).toISOString()
                        : '';
                let dateTo =
                    Boolean(fuhfumVisitReadmissionToDate) && fuhfumVisitReadmissionDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(fuhfumVisitReadmissionToDate).toISOString()
                        : '';

                if (fuhfumVisitReadmissionTab === 1) {
                    const data = await fetchFuhFumVisitsWidget(fuhfumVisitReadmissionDateFilter, dateFrom, dateTo);
                    setVisits(data);
                    setIsFetched(true);
                } else {
                    const data = await fetchFuhFumReadmissionsWidget(fuhfumVisitReadmissionDateFilter, dateFrom, dateTo);
                    setReadmissions(data);
                    setIsFetched(true);
                }
            };
            changeFilter();
        }

        if (
            fuhfumVisitReadmissionDateFilter !== WidgetTimeFilterEnum.Custom &&
            Boolean(fuhfumVisitReadmissionFromDate) &&
            Boolean(fuhfumVisitReadmissionToDate)
        ) {
            setFuhfumVisitReadmissionFromDate(null);
            setFuhfumVisitReadmissionToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsFetched, fuhfumVisitReadmissionDateFilter, fuhfumVisitReadmissionFromDate, fuhfumVisitReadmissionToDate, fuhfumVisitReadmissionTab]);

    const changeFromDate = (date: any) => {
        setFuhfumVisitReadmissionFromDate(date);
    };

    const changeToDate = (date: any) => {
        setFuhfumVisitReadmissionToDate(date);
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setFuhfumVisitReadmissionTab(newValue);
    };

    const visitsColumns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsDocumentationCompleted === true ? 'green' : 'red'),
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsDocumentationCompleted === true ? 'green' : 'red'),
        },
        {
            field: 'AdmitDate',
            headerName: 'Admit Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : dateFormatter(new Date(params.row.AdmitDate))}`,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsDocumentationCompleted === true ? 'green' : 'red'),
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? '-' : dateFormatter(new Date(params.row.DischargeDate))}`,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsDocumentationCompleted === true ? 'green' : 'red'),
        },
        {
            field: 'LastAttemptDate',
            headerName: 'Last Visited Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.LastAttemptDate) ? '-' : dateFormatter(new Date(params.row.LastAttemptDate))}`,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsDocumentationCompleted === true ? 'green' : 'red'),
        },
    ];

    const readmissionsColumns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'DischargeDate',
            headerName: 'Last Discharge Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? '-' : dateFormatter(new Date(params.row.DischargeDate))}`,
        },
        {
            field: 'AdmitDate',
            headerName: 'Current Admission Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : dateFormatter(new Date(params.row.AdmitDate))}`,
        },
        {
            field: 'Facility',
            headerName: 'Current Facility',
            flex: 2,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer">FUHFUM Visit / Readmission Summary</span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                FUHFUM Visits :
                                            </Typography>
                                            <div>
                                                <Typography variant="caption">7 day rule : BH admission only post discharge.</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">30 day rule : PH admission only post discharge.</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption">
                                                    FUHFUM members who are Scheduled or FUHFUMInProgress who have not yet met the above conditions should
                                                    display in red and FUHFUM members who have had the appropriate visits should display in green.
                                                </Typography>
                                            </div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                FUHFUM Readmissions :
                                            </Typography>
                                            <Typography variant="caption">
                                                The member was a past FUHFUM referral, and all required visits and documentation were completed on time.
                                                Additionally, the member has a new Transitional Care opened within 30 days of discharge from their previous
                                                Transitional Care, for which they were a FUHFUM referral.
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={fuhfumVisitReadmissionDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setFuhfumVisitReadmissionDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {fuhfumVisitReadmissionDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fuhfumVisitReadmissionFromDate) ? fuhfumVisitReadmissionFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(fuhfumVisitReadmissionToDate) ? fuhfumVisitReadmissionToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} display={'flex'}>
                            <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={fuhfumVisitReadmissionTab} onChange={handleAlertsTabChange}>
                                <Tab value={1} label="FUHFUM Visits" />
                                <Tab value={2} label="FUHFUM Readmissions" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                                    {fuhfumVisitReadmissionTab === 1 ? (
                                        <DataGrid
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                            }}
                                            rows={visits}
                                            columns={visitsColumns}
                                            pageSize={pageSize}
                                            rowsPerPageOptions={[5]}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            getRowId={(row) => `${row.Id}-${row.AdmitDate}-${generateRandomId()}`}
                                            getRowHeight={() => 'auto'}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                                [`& .green`]: {
                                                    color: 'green',
                                                },
                                                [`& .red`]: {
                                                    color: 'red',
                                                },
                                                [`& .yellow`]: {
                                                    color: 'gold',
                                                },
                                                [`& .black`]: {
                                                    color: 'rgba(0,0,0,0.87)',
                                                },
                                            }}
                                        />
                                    ) : (
                                        <DataGrid
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                            }}
                                            rows={readmissions}
                                            columns={readmissionsColumns}
                                            pageSize={pageSize}
                                            rowsPerPageOptions={[5]}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            getRowId={(row) => `${row.Id}-${row.AdmitDate}-${generateRandomId()}`}
                                            getRowHeight={() => 'auto'}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default FuhFumVisitsReadmissions;
