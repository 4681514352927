import { Box, FormGroup, Grid, Paper, Stack, Typography, Button, Popover, Tooltip, Divider, Modal, Link, IconButton } from '@mui/material';
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { hedisMetricsList, setShowPreviousCharts } from '../../../store/memberDemographicsDetails.slice';
import { getDefaultDiagnosis } from '../../../store/encounter.slice';
import { useState, useEffect } from 'react';
import phoneFormat from '../../../utils/phoneFormat';
import MemberPreviousChartsModal from '../MemberPreviousChartsModal/MemberPreviousChartsModal';
import { modalStyle } from '../../../utils/constants';
import { HedisMetricsVisit } from '../../../Models/HedisMetrics/HedisMetricsVisit.model';
import { ClaimSourceEnum } from '../../../Enum/ClaimSourceEnum';
import ScienceIcon from '@mui/icons-material/Science';
import { noteFormatter } from '../../HIE/Modals/TeamNotificationModal';
import { HedisMetricStatusEnum } from '../../../Enum/HedisMetricStatusEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';

const MemberInformation = (props) => {
    const { memberId } = props;
    const dispatch = useDispatch();
    const { chartDetails, previousMemberCharts } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { memberDefaultDiagnosis } = useSelector((state: RootState) => state.encounter);
    const { memberHealthPlans } = useSelector((state: RootState) => state.memberDetail);
    const [diagnosisAnchorEl, setDiagnosisAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showHedisDetail, setShowHedisDetail] = useState(false);
    const [hedisDetail, setHedisDetail] = useState<HedisMetricsVisit>(null);
    const [hedisType, setHedisType] = useState<string>('');
    const [selectedTooltipHedisType, setSelectedTooltipHedisType] = useState<string>('');

    const handleClickDiagnosis = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDiagnosisAnchorEl(event.currentTarget);
    };

    const handleCloseDiagnosis = () => {
        setDiagnosisAnchorEl(null);
    };

    const diagnosisOpen = Boolean(diagnosisAnchorEl);
    const diagnosisId = diagnosisOpen ? 'simple-popover' : undefined;

    const showStar = (team) => {
        if (!team) return false;
        if (team?.Id === chartDetails?.Team?.Id) return true;
        return false;
    };

    const fontColor = (metric) => {
        if (metric?.IsExpired || metric?.MetricStatus === HedisMetricStatusEnum.Expired) {
            return 'error.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed || metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return 'success.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return 'warning.main';
        } else {
            return 'rgba(0,0,0, 0.4)';
        }
    };

    const prepareHedisStatus = (metric) => {
        if (metric?.IsExpired || metric?.MetricStatus === HedisMetricStatusEnum.Expired) {
            return `Expired`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed) {
            return `Completed`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return `Completed And Controlled`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return `Completed And Not Controlled`;
        }
    };

    const handleViewPreviousCharts = () => {
        dispatch(setShowPreviousCharts(true));
    };

    useEffect(() => {
        dispatch(getDefaultDiagnosis(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prepareHedis = (metric: HedisMetricsVisit, hedisType: string) => {
        setHedisDetail(metric);
        setHedisType(hedisType);
        setShowHedisDetail(true);
    };

    const prepareHedisDetail = () => {
        if (Boolean(chartDetails?.HedisSummary)) {
            var hedisDetailList = { controlledList: [], notControlledList: [], notCompletedList: [] };
            hedisMetricsList.forEach((metric, index) => {
                let metricInfo = chartDetails.HedisSummary.find((h) => h.HedisMetricType === metric.type);
                let metricInfoCopy = {
                    ...metricInfo,
                    Label: metric.label,
                    Type: metric.type,
                    FontColor: fontColor(metricInfo),
                    IsShow: false,
                    ToolInfo: prepareToolInfo(metric.type),
                };
                if (
                    metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.Completed ||
                    metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled
                ) {
                    hedisDetailList.controlledList.push(metricInfoCopy);
                } else if (metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
                    hedisDetailList.notControlledList.push(metricInfoCopy);
                } else {
                    hedisDetailList.notCompletedList.push(metricInfoCopy);
                }
            });
            return [
                {
                    Name: 'Completed And Not Controlled',
                    List: hedisDetailList.notControlledList,
                },
                {
                    Name: 'Not Completed',
                    List: hedisDetailList.notCompletedList,
                },
                {
                    Name: 'Completed And Controlled',
                    List: hedisDetailList.controlledList,
                },
            ].filter((h) => h.List.length);
        }
    };

    const prepareToolInfo = (type: number) => {
        switch (type) {
            case HedisMetricsTypeEnum.BP:
                return (
                    <div>
                        <Typography variant="body2">Member who has an ICD 10 of I10, within a rolling 12 months</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Controlled BP :
                        </Typography>

                        <Typography variant="caption">
                            CPT Codes 3074F or 3075F Systolic (must have one or the other of these) and 3078F or 3079F Diastolic (must have one or the other of
                            these)
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Controlled Bp :
                        </Typography>
                        <Typography variant="caption">CPT Codes 3077F and 3080F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.ProviderVisits:
                return (
                    <div>
                        <Typography variant="body2">Member is 20 years of age or older, within a rolling 12 months</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Visits Completed :
                        </Typography>

                        <Typography variant="caption">
                            Any of the CPT codes - 92002, 92004, 92012, 92014, 93005, 97597, 98966, 98967, 98968, 98969, 98970, 98971, 98972, 99211, 99212,
                            99213, 99214, 99215, 99201, 99202, 99203, 99204, 99205, 99223, 99241, 99242, 99243, 99244, 99245, 99281, 99282, 99283, 99284, 99285,
                            99304, 99305, 99306, 99307, 99308, 99309, 99310, 99334, 99335, 99336, 99337, 99341, 99342, 99343, 99344, 99345, 99347, 99348, 99349,
                            99350, 99381, 99382, 99383, 99384, 99385, 99386, 99387, 99391, 99392, 99393, 99394, 99395, 99396, 99397, 99401, 99402, 99403, 99404,
                            99411, 99412, 99421, 99422, 99423, 99429, 99441, 99442, 99443, 99444, 99457, 99483, G0402, G0438, G0439, G0463, T1015, H1000, G0071,
                            G2010, G2012, G2061, G2062, G2063, G2250, G2251, G2252
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            No Visits :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.BMI:
                return (
                    <div>
                        <Typography variant="body2">For all adults 18-74, within a rolling 12 months</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            BMI Completed :
                        </Typography>

                        <Typography variant="caption">
                            Any of the ICD 10 codes - Z38.20, Z68, Z68.1, Z68.2, Z68.20, Z68.21, Z68.22, Z68.23, Z68.24, Z68.25, Z68.26, Z68.27, Z68.28, Z68.29,
                            Z68.3, Z68.30, Z68.31, Z68.32, Z68.33, Z68.34, Z68.35, Z68.36, Z68.37, Z68.38, Z68.39, Z68.4, Z68.41, Z68.42, Z68.43, Z68.44,
                            Z68.45, Z68.5, Z68.51, Z68.52, Z68.53, Z68.54
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            No BMI Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.BreastCancerScreening:
                return (
                    <div>
                        <Typography variant="body2">
                            For females 50-74 years of age within prior 12 months, of either the baseline year, or to-date in the performance year
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Screened :
                        </Typography>

                        <Typography variant="caption">
                            Any of the CPT codes - 77055, 77056, 77057, 77061, 77062, 77065, 77066, 77067, G0202, G0204, G0206
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Screened :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.HgbA1c:
                return (
                    <div>
                        <Typography variant="body2">For members aged 18-75 who have an ICD 10 of E10-E11</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Controlled :
                        </Typography>
                        <Typography variant="caption">Any of the CPT codes 3051F or 3044F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Controlled :
                        </Typography>
                        <Typography variant="caption">Any of the CPT codes 3046F or 3052F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.DiabeticEyeExam:
                return (
                    <div>
                        <Typography variant="body2">
                            For members who have an ICD 10 of E10-E11 and are between the ages of 18-75 in the rolling 12 month time frame
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Screened :
                        </Typography>

                        <Typography variant="caption">
                            Any of the CPT codes 67028, 67030, 67031, 67036, 67039, 67040, 67041, 67042, 67043, 67101, 67105, 67107, 67108, 67110, 67113, 67121,
                            67141, 67145, 68208, 67210, 67218, 67220, 67221, 67227, 67228, 92002, 92004, 92012, 92014, 92018, 92019, 92134, 92225, 92226, 92227,
                            92228, 92230, 92235, 92240, 92550, 92260,99203, 99204, 99205, 99213, 99214, 99215, 99242, 99243, 99244, 99245
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Screened :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.MedicationReconciliation:
                return (
                    <div>
                        <Typography variant="body2">Within 30 days of discharge from a facility</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Documented :
                        </Typography>

                        <Typography variant="caption">Any of the CPT codes 1111F or 1159F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.DepressionScreening:
                return (
                    <div>
                        <Typography variant="body2">For members aged greater than 12, in the rolling 6 month time frame</Typography>
                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Screened :
                        </Typography>
                        <Typography variant="caption">CPT code G8510 OR G8431</Typography>
                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Screened :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.DepressionFollowUp:
                return (
                    <div>
                        <Typography variant="body2">For members aged greater than 12 with a PHQ9{'>'}14 in the rolling 6 month time frame</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Documented :
                        </Typography>

                        <Typography variant="caption">Had an encounter with a CPT code of G8431 within 30 days of the documented PHQ9 score</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.LipidForBhDx:
                return (
                    <div>
                        <Typography variant="body2">
                            For members aged 18-64 who has an ICD 10 of F20.9, F25.0, F25.9, F31.32, F31.2, F31.3, F31.81, F31.89, F31.9 within 180 days
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Controlled :
                        </Typography>

                        <Typography variant="caption">CPT code 3048F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Controlled :
                        </Typography>
                        <Typography variant="caption">Any of the CPT codes 3049F and 3050F and their CPT = 80061, 83700-83701, 83704, 83721</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
            case HedisMetricsTypeEnum.HgbA1cForBhDx:
                return (
                    <div>
                        <Typography variant="body2">
                            For members aged 18-64 who has an ICD 10 of F20.9, F25.0, F25.9, F31.32, F31.2, F31.3, F31.81, F31.89, F31.9 within 180 days
                        </Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Controlled :
                        </Typography>

                        <Typography variant="caption">Any of the CPT codes 3044F or 3051F</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Controlled :
                        </Typography>
                        <Typography variant="caption">Any of the CPT codes 3045F, 3052F and their CPT = 83036-83037</Typography>

                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                            Not Documented :
                        </Typography>
                        <Typography variant="caption">No CPT codes recorded</Typography>
                    </div>
                );
        }
    };

    const hedisDetails = prepareHedisDetail();

    return (
        <>
            <MemberPreviousChartsModal />
            <Box sx={{ backgroundColor: '#ddd', p: 1 }}>
                <FormGroup sx={{ marginRight: -1 }}>
                    <Grid container spacing={1}>
                        <Paper square elevation={3} sx={{ p: 2, display: 'flex', width: '100%' }}>
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Last Seen:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.LastCHPVisit ? moment(chartDetails?.LastCHPVisit).format('MM/DD/yyyy') : '-'}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Risk Score:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.RiskScore}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Plan:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryHealthPlan?.Name}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Insurance:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {memberHealthPlans.map((plan, index) => {
                                            return (
                                                <Grid item display={'flex'} key={index}>
                                                    <Typography textAlign={'right'} fontStyle={'italic'} variant="body2" marginRight={1}>
                                                        {plan.Priority ? plan.Priority : '-'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {plan?.Name ? plan?.Name : '-'}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CC:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CCTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CCTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        NP:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.NPTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.NPTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        BH:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.BHTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.BHTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CL:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CLTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CLTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CHW:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CHWTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CHWTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={4}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Address:</Typography>
                                    <Stack justifyContent={'flex-end'} spacing={0}>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.Address1 ? chartDetails?.MainAddress?.Address1 : '-'}${
                                                chartDetails?.MainAddress?.Address2 ? ', ' + chartDetails?.MainAddress?.Address2 : ''
                                            } `}
                                        </Typography>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.City ? chartDetails?.MainAddress?.City : '-'}${
                                                chartDetails?.MainAddress?.State ? ', ' + chartDetails?.MainAddress?.State : ''
                                            } ${chartDetails?.MainAddress?.ZipCode ? chartDetails?.MainAddress?.ZipCode : ''}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {chartDetails.IsDifferMeetingAddress && (
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                        <Typography variant="body2">Meeting Address:</Typography>
                                        <Stack justifyContent={'flex-end'} spacing={0}>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.Address1 ? chartDetails?.MainMeetingAddress?.Address1 : '-'}${
                                                    chartDetails?.MainMeetingAddress?.Address2 ? ', ' + chartDetails?.MainMeetingAddress?.Address2 : ''
                                                } `}
                                            </Typography>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.City ? chartDetails?.MainMeetingAddress?.City : '-'}${
                                                    chartDetails?.MainMeetingAddress?.State ? ', ' + chartDetails?.MainMeetingAddress?.State : ''
                                                } ${chartDetails?.MainMeetingAddress?.ZipCode ? chartDetails?.MainMeetingAddress?.ZipCode : ''}`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Phones:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {chartDetails?.Phones?.map((phone, index) => {
                                            return (
                                                <Grid key={index} item display={'flex'} color={phone.IsPrimary ? 'primary.main' : 'rgba(0,0,0,0.5)'}>
                                                    <Typography textAlign={'right'} variant="body2" marginRight={1}>
                                                        {phone.Textable ? 'Text -' : 'No Text -'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {phone.Number ? phoneFormat(phone.Number) : '-'}
                                                    </Typography>
                                                    {phone.IsPrimary && (
                                                        <Tooltip title="Primary Phone">
                                                            <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 1 }} />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Primary Language:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryLanguage?.Name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={2}>
                                <Stack spacing={2}>
                                    <Button aria-describedby={diagnosisId} variant="outlined" onClick={handleClickDiagnosis} sx={{ width: '100%' }}>
                                        View Primary Diagnosis
                                    </Button>
                                    <Popover
                                        id={diagnosisId}
                                        open={diagnosisOpen}
                                        anchorEl={diagnosisAnchorEl}
                                        onClose={handleCloseDiagnosis}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{ style: { maxWidth: '400px' } }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            <Typography variant="body1" fontWeight={700}>
                                                Primary Diagnosis
                                            </Typography>
                                            <Typography variant="body2">
                                                {Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Name
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Popover>
                                    <Button
                                        disabled={previousMemberCharts.length > 0 ? false : true}
                                        aria-describedby={diagnosisId}
                                        variant="outlined"
                                        onClick={handleViewPreviousCharts}
                                        sx={{ width: '100%' }}
                                    >
                                        View Previous Charts ({previousMemberCharts.length})
                                    </Button>
                                </Stack>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Stack direction="row" spacing={1} marginTop={1} justifyContent={'center'}>
                        {hedisDetails?.map((metric: any, index) => (
                            <Stack
                                direction="column"
                                key={`group-${index}`}
                                sx={{
                                    borderRight: index === hedisDetails.length - 1 ? 'none' : '2px solid #9E9E9E',
                                    pr: index === hedisDetails.length - 1 ? '0' : '10px',
                                    pl: index === 0 ? '0' : '10px',
                                }}
                            >
                                <Typography variant="body2" sx={{ whiteSpace: 'nowrap', textAlign: 'center', color: '#7c7c7c' }}>
                                    {metric.Name}
                                </Typography>
                                <Stack direction="row" columnGap={2} p={1} pl={0} flexWrap="wrap" rowGap={2} justifyContent="center">
                                    {metric.List.map((hedis, index) => {
                                        const color = hedis.FontColor;
                                        return Boolean(hedis.IsExpired || hedis.MetricStatus === HedisMetricStatusEnum.Expired) ? (
                                            <Paper
                                                elevation={3}
                                                key={index}
                                                sx={{
                                                    position: 'relative',
                                                    padding: '20px',
                                                    textAlign: 'center',
                                                    overflow: 'hidden',
                                                    minWidth: '200px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    Boolean(hedis?.DateLastMet) && prepareHedis(hedis, hedis.Label);
                                                }}
                                            >
                                                {/* Ribbon */}
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '8px',
                                                        left: '-22px',
                                                        backgroundColor: '#d32f2f',
                                                        color: 'white',
                                                        padding: '3px 20px',
                                                        transform: 'rotate(-45deg)',
                                                        zIndex: 1,
                                                        fontSize: '11px',
                                                        lineHeight: '1',
                                                    }}
                                                >
                                                    Expired
                                                </Typography>
                                                <Typography fontWeight={700} color={color} variant="body1" pb={0.5} sx={{ whiteSpace: 'nowrap' }}>
                                                    {hedis.Label}
                                                    <Tooltip
                                                        disableHoverListener
                                                        onClose={() => setSelectedTooltipHedisType('')}
                                                        sx={{ cursor: 'pointer' }}
                                                        open={hedis?.Type === selectedTooltipHedisType}
                                                        title={hedis?.ToolInfo}
                                                        PopperProps={{ style: { zIndex: 1000 } }}
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedTooltipHedisType(hedis.Type);
                                                            }}
                                                            sx={{ paddingTop: '0px', paddingBottom: '0px', cursor: 'pointer' }}
                                                        >
                                                            <InfoIcon
                                                                sx={{ width: '18px', height: '18px', paddingTop: '0px', paddingBottom: '0px', color: color }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {Boolean(hedis?.HedisAlert) ? (
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{ width: '18px', height: '18px', paddingTop: '0px', paddingBottom: '0px', color: color }}
                                                        >
                                                            <ScienceIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Typography>
                                                <Typography color={color} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                                    <Typography color={color} variant="body2">
                                                        {hedis?.DateLastMet && !hedis?.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                                        {hedis?.DateLastMet ? moment(hedis?.DateLastMet).format('MM/DD/yyyy') : '-'}
                                                    </Typography>
                                                </Typography>
                                            </Paper>
                                        ) : (
                                            <Paper
                                                elevation={3}
                                                key={index}
                                                sx={{
                                                    textAlign: 'center',
                                                    p: 1.7,
                                                    borderLeftWidth: 4,
                                                    borderLeftColor: color,
                                                    borderLeftStyle: 'solid',
                                                    cursor: Boolean(hedis?.DateLastMet) ? 'pointer' : '',
                                                }}
                                                onClick={() => {
                                                    Boolean(hedis?.DateLastMet) && prepareHedis(hedis, hedis.Label);
                                                }}
                                            >
                                                <Typography fontWeight={700} color={color} variant="body1" pb={0.5} sx={{ whiteSpace: 'nowrap' }}>
                                                    {hedis.Label}
                                                    <Tooltip
                                                        disableHoverListener
                                                        onClose={() => setSelectedTooltipHedisType('')}
                                                        sx={{ cursor: 'pointer' }}
                                                        open={hedis?.Type === selectedTooltipHedisType}
                                                        title={hedis?.ToolInfo}
                                                        PopperProps={{ style: { zIndex: 1000 } }}
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedTooltipHedisType(hedis.Type);
                                                            }}
                                                            sx={{ paddingTop: '0px', paddingBottom: '0px', cursor: 'pointer' }}
                                                        >
                                                            <InfoIcon
                                                                sx={{ width: '18px', height: '18px', paddingTop: '0px', paddingBottom: '0px', color: color }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {Boolean(hedis?.HedisAlert) ? (
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{ width: '18px', height: '18px', paddingTop: '0px', paddingBottom: '0px', color: color }}
                                                        >
                                                            <ScienceIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Typography>
                                                <Typography color={color} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                                    <Typography color={color} variant="body2">
                                                        {hedis?.DateLastMet && !hedis?.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                                        {hedis?.DateLastMet ? moment(hedis?.DateLastMet).format('MM/DD/yyyy') : '-'}
                                                    </Typography>
                                                </Typography>
                                            </Paper>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                    <Grid item xs={12}>
                        <Stack direction={'row'} spacing={2} sx={{ backgroundColor: 'white' }} justifyContent={'center'}>
                            <Typography variant="body2" fontWeight={'bold'} color={'error.main'}>
                                Expired
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'} color={'success.main'}>
                                Completed and Controlled
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'} color={'warning.main'}>
                                Completed and Not Controlled
                            </Typography>
                        </Stack>
                    </Grid>
                </FormGroup>
            </Box>
            <Modal
                open={showHedisDetail}
                onClose={() => {
                    setShowHedisDetail(false);
                }}
            >
                <Box sx={modalStyle} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            {hedisType}
                            <Typography variant="body1" sx={{ color: fontColor(hedisDetail), fontSize: 14 }}>
                                {prepareHedisStatus(hedisDetail)}
                            </Typography>
                        </Typography>
                        <Divider />
                    </Stack>
                    <Stack spacing={2} mt={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                CPT Code :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.CPTcode) ? hedisDetail?.CPTcode : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                {hedisDetail?.DateLastMet && !hedisDetail.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.DateLastMet) ? moment(hedisDetail?.DateLastMet).format('MM/DD/yyyy') : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Number :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.ClaimNumber) ? hedisDetail?.ClaimNumber : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Source :
                            </Typography>
                            <Typography>
                                {Boolean(hedisDetail?.ClaimSourceName)
                                    ? hedisDetail.ClaimSource === ClaimSourceEnum.ChpClaims && hedisDetail.ProviderName
                                        ? `${hedisDetail.ClaimSourceName} (${hedisDetail.ProviderName})`
                                        : hedisDetail.ClaimSourceName
                                    : '-'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Notes :
                            </Typography>
                            <Typography flex={1}>{Boolean(hedisDetail?.Note) ? hedisDetail?.Note : '-'}</Typography>
                        </Stack>
                        {Boolean(hedisDetail?.HedisAlert) && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Lab Alert :
                                </Typography>
                                <div>{noteFormatter(hedisDetail?.HedisAlert)}</div>
                            </Stack>
                        )}
                        {hedisDetail?.DocumentUri && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Document :
                                </Typography>
                                <Link target="_blank" underline="none" href={hedisDetail?.DocumentUri} sx={{ mt: '2px !important' }}>
                                    {hedisDetail?.DocumentName}
                                </Link>
                            </Stack>
                        )}
                        {hedisDetail?.ClaimSource === ClaimSourceEnum.HealthPlan && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Updated Based On Health Plan :
                                </Typography>
                                <Typography>
                                    {Boolean(hedisDetail?.DateUpdatedByHealthPlan) ? moment(hedisDetail?.DateUpdatedByHealthPlan).format('MM/DD/yyyy') : '-'}
                                </Typography>
                            </Stack>
                        )}
                        <Divider />
                        <Box>
                            <Stack direction="row" spacing={1} style={{ float: 'right' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowHedisDetail(false);
                                        setHedisDetail(null);
                                    }}
                                >
                                    Ok
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};
export default MemberInformation;
