import { Button, CircularProgress } from '@mui/material';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import React from 'react';

interface StatusSaveButtonProps {
    label?: string;
    disabled?: boolean;
    saving?: boolean;
    fullWidth?: boolean;
    onClick?: Function;
    children?: React.ReactNode;
}

const StatusSaveButton: React.FC<StatusSaveButtonProps> = ({ label, disabled, saving, onClick, fullWidth, children }) => {
    return (
        <Button
            fullWidth={fullWidth}
            variant="contained"
            color="primary"
            disabled={disabled}
            // sx={{ ml: 'auto!important' }}
            onClick={() => {
                onClick();
            }}
        >
            {!saving ? (
                <DoneAllRoundedIcon style={{ marginRight: '10px', color: 'white' }} />
            ) : (
                <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
            )}
            <span style={{ color: 'white' }}>{children ?? label}</span>
        </Button>
    );
};

export default React.memo(StatusSaveButton);
