import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { Box, Button, CircularProgress, FormControl, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { exportCallHistoryReport, getCallerHistory } from '../../../../store/onboardingWorkflow.slice';
import moment from 'moment';
import phoneFormat from '../../../../utils/phoneFormat';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { isDefaultDate } from '../../../../utils/common';
import { getCallerHistoryCallItem } from '../../../../store/adminOnboarding.slice';
import CallHistoryCallItemDialog from './CallHistoryCallItemDialog';
import GetAppIcon from '@mui/icons-material/GetApp';

const CallerHistory = ({ selectedOnboarder }) => {
    const dispatch = useDispatch();
    const { callerHistory, exporting } = useSelector((state: RootState) => state.onboardingWorkflowSlice);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');

    const handleRowClick = (params) => {
        const callItemId = params.row.CallItemId;
        dispatch(getCallerHistoryCallItem(callItemId));
    };

    const isValidDate = (date: Date) => moment(date).isValid();

    const handleSearchCalls = () => {
        if (!isValidDate(startDate)) {
            setStartDateError('Invalid Date');
        } else {
            setStartDateError('');
        }
        if (!isValidDate(endDate)) {
            setEndDateError('Invalid Date');
        } else {
            setEndDateError('');
        }
        if (selectedOnboarder.Id && isValidDate(startDate) && isValidDate(endDate)) {
            dispatch(getCallerHistory(selectedOnboarder.Id, startDate, endDate));
        }
    };

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };

    const exportCallHistoryReportClick = async () => {
        dispatch(exportCallHistoryReport(selectedOnboarder, startDate, endDate));
    };

    const columns: GridColDef[] = [
        {
            field: 'FirstName',
            headerName: 'Name',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.LastName}, ${params.row.FirstName}`,
        },
        {
            field: 'PhoneString',
            headerName: 'Phone Number',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => phoneFormat(params.row.PhoneString),
        },
        {
            field: 'PopulationName',
            headerName: 'Population',
            flex: 1,
        },
        {
            field: 'TimeCallPlaced',
            headerName: 'Date Called',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.TimeCallPlaced) ? '-' : moment(params.row.TimeCallPlaced).format('MM-DD-YYYY')}`,
        },
        {
            field: 'TimeCalled',
            headerName: 'Time Called',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.TimeCallPlaced) ? '-' : moment(params.row.TimeCallPlaced).format('hh:mm:ss a')}`,
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 65 : 68) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (selectedOnboarder.Id) {
            dispatch(getCallerHistory(selectedOnboarder.Id, new Date(), new Date()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOnboarder]);

    return (
        <Box p={2}>
            <CallHistoryCallItemDialog />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction="row" spacing={2} alignItems="center" my={2}>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="Start Date:"
                                value={startDate}
                                minDate={new Date('2017-01-01')}
                                onChange={handleStartDateChange}
                                renderInput={(params) => (
                                    <TextField {...params} error={startDateError.length > 0 ? true : false} helperText={startDateError} required />
                                )}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="End Date:"
                                value={endDate}
                                minDate={new Date('2017-01-01')}
                                onChange={handleEndDateChange}
                                renderInput={(params) => (
                                    <TextField {...params} error={endDateError.length > 0 ? true : false} helperText={endDateError} required />
                                )}
                            />
                        </FormControl>

                        <Button variant="contained" color="primary" onClick={handleSearchCalls}>
                            Search
                        </Button>
                    </Stack>
                    <Typography variant="h6">Total Calls: {callerHistory?.length}</Typography>
                    <Button variant="contained" color="primary" disabled={exporting ? true : false} onClick={exportCallHistoryReportClick}>
                        {!exporting ? (
                            <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                        ) : (
                            <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                        )}
                        <span style={{ color: 'white' }}>Export to Excel</span>
                    </Button>
                </Stack>
            </LocalizationProvider>
            <div style={{ width: '100%', height: '65vh' }}>
                <DataGrid
                    rows={callerHistory}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowHeight={() => 'auto'}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => row.Id}
                    onRowClick={handleRowClick}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </div>
        </Box>
    );
};

export default CallerHistory;
