import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, ERROR, SUCCESS } from '../utils/constants';
import axios from 'axios';
import { AlertColor } from '@mui/material';
import { dictionaryToArray } from '../utils/mappings';
import { Role } from '../Models/Role/Role.model';

const ac: AlertColor = SUCCESS;

const timeout = 3000;
const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        mapAPIKey: 'AIzaSyD0ZVhNqOlGF37mVlyQX2DZUbnvDaLROus',
        userProviderId: '',
        activeProviders: [],
        allFacilities: [],
        allLanguages: [],
        allProviders: [],
        currentProvider: null,
        downLoading: false,
        error: '',
        encounterClaimProviders: [],
        genders: [],
        healthPlans: [],
        isLoading: false,
        maritalStatuses: [],
        message: '',
        messageType: ac,
        permissions: [],
        preferredContactOptions: [],
        roles: [] as Role[],
        showMessage: false,
    },
    reducers: {
        _setAllLanguages(state, action) {
            state.allLanguages = action.payload;
        },
        _setActiveProviders(state, action) {
            state.activeProviders = action.payload;
        },
        _setAllProviders(state, action) {
            state.allProviders = action.payload;
        },
        _setCurrentProvider(state, action) {
            state.currentProvider = action.payload;
        },
        _setDownLoading(state, action) {
            state.downLoading = action.payload;
        },
        _setEncounterClaimProviders(state, action) {
            state.encounterClaimProviders = action.payload;
        },
        _setError(state, action) {
            state.error = action.payload;
        },
        _setFacilities(state, action) {
            state.allFacilities = action.payload;
        },
        _setGenders(state, action) {
            state.genders = action.payload;
        },
        _setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        _setMaritalStatuses(state, action) {
            state.maritalStatuses = action.payload;
        },
        _setMessage(state, action) {
            state.message = action.payload;
        },
        _setMessageType(state, action) {
            state.messageType = action.payload;
        },
        _setPermissions(state, action) {
            state.permissions = action.payload;
        },
        _setPreferredContactOptions(state, action) {
            state.preferredContactOptions = action.payload;
        },
        _setRoles(state, action) {
            state.roles = action.payload;
        },
        _setShowMessage(state, action) {
            state.showMessage = action.payload;
        },
        _setHealthPlans(state, action) {
            state.healthPlans = action.payload;
        },
    },
});

const {
    _setAllLanguages,
    _setAllProviders,
    _setActiveProviders,
    _setCurrentProvider,
    _setDownLoading,
    _setEncounterClaimProviders,
    _setError,
    _setFacilities,
    _setGenders,
    _setIsLoading,
    _setMaritalStatuses,
    _setMessage,
    _setMessageType,
    _setPermissions,
    _setPreferredContactOptions,
    _setRoles,
    _setShowMessage,
    _setHealthPlans,
} = sharedSlice.actions;

export const fetchAllLanguages = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/common/getalllanguage`);
    dispatch(_setAllLanguages(response.data));
};

export const fetchAllProviders = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/provider/getallprovider`);
    dispatch(_setAllProviders(response.data));
};

export const fetchAllFacilities = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/facility`);
    dispatch(_setFacilities(response.data));
};

export const fetchEncounterClaimProviders = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/provider/getprovidersforencounter`);
    dispatch(_setEncounterClaimProviders(response.data));
};

export const fetchGenders = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/common/getgender`);
    const options = dictionaryToArray(response.data);
    dispatch(_setGenders(options));
};

export const fetchMaritalStatuses = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/common/getmaritalstatus`);
    const options = dictionaryToArray(response.data);
    dispatch(_setMaritalStatuses(options));
};

export const fetchPermissions = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/role/permissions`);
    dispatch(_setPermissions(response.data));
};

export const fetchPreferredContactOptions = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/common/getpreferredcontact`);
    const options = dictionaryToArray(response.data);
    dispatch(_setPreferredContactOptions(options));
};

export const fetchProvider =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/provider/getproviderbyid?id=${providerId}`);
        dispatch(_setCurrentProvider(response.data));
    };

export const fetchProviderOptions =
    (searchString: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/provider/provideroptions/${searchString.toLowerCase()}`);
        dispatch(_setActiveProviders(response.data));
    };

export const fetchRoles = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/role/roles`);
    dispatch(_setRoles(response.data));
};

export const setDownLoading =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setDownLoading(params));
    };

export const setError =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setError(value));
        setTimeout(() => {
            dispatch(_setError(null));
        }, timeout);
    };

export const setIsLoading =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsLoading(value));
    };

export const setShowMessage =
    (show: boolean, message: string, messageType: AlertColor): AppThunk =>
    (dispatch) => {
        dispatch(_setShowMessage(show));
        dispatch(_setMessage(message));
        dispatch(_setMessageType(messageType));
    };

export const setHealthPlans =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setHealthPlans(value));
    };

export const fetchHealthPlanList = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/healthplan/gethealthplans`);
    // console.log("health plan list response: ", response);
    dispatch(setHealthPlans(response.data.filter((plan) => plan.IsEnableAutomatedBillings)));
};

export const handleErrorMessage =
    (error: any): AppThunk =>
    async (dispatch, getState) => {
        let errorMessage = 'An unexpected error occurred.';

        if (error?.response) {
            // Check if the error has a response object
            const { data, status, statusText } = error.response;

            if (data) {
                if (typeof data === 'string') {
                    errorMessage = data;
                } else if (data.error) {
                    errorMessage = data.error;
                } else if (data.message) {
                    errorMessage = data.message;
                } else {
                    errorMessage = JSON.stringify(data);
                }
            } else {
                errorMessage = `HTTP ${status}: ${statusText}`;
            }
        } else if (error?.message) {
            errorMessage = error.message;
        } else if (typeof error === 'string') {
            errorMessage = error;
        }
        console.log('errorMessage: ', errorMessage);
        dispatch(setIsLoading(false));
        dispatch(setShowMessage(true, errorMessage, ERROR));
    };

export default sharedSlice.reducer;
