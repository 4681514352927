import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { RootState } from '../../../reducers';
import { fetchMemberAccessReport, exportMemberAccessReport } from '../../../store/memberAccessReport.slice';
import { parentUrl } from '../../../utils/constants';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import moment from 'moment';

const dateFilterList = [
    { Id: TimePeriodFilterEnum.Last1Month, Value: TimePeriodFilterEnum.Last1MonthDescription },
    { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
    { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
    { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
    { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
];

const MemberAccessReport = () => {
    const dispatch = useDispatch();
    const { memberAccessData } = useSelector((state: RootState) => state.memberAccessReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const [searchKey, setSearchKey] = useState('');
    const [dateFilter, setDateFilter] = useState(TimePeriodFilterEnum.Last1Month);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const columns: GridColDef[] = [
        {
            field: 'AHCCCSId',
            headerName: 'AHCCCS_ID',
            minWidth: 120,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            minWidth: 200,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 120,
        },
        {
            field: 'CreationTime',
            headerName: 'Accessed On',
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography>{moment(params.row.CreationTime).isValid() ? moment(params.row.CreationTime).format('MM/DD/yyyy hh:mm a') : '-'}</Typography>
                );
            },
        },
        {
            field: 'ProviderName',
            headerName: 'Accessed By',
            minWidth: 180,
        },
        {
            field: 'Type',
            headerName: 'Type',
            minWidth: 170,
        },
        {
            field: 'Description',
            headerName: 'Description',
            minWidth: 500,
        },
    ];

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box style={{ width: '100%', marginLeft: '8px' }} component={'div'}>
                <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Box width={'100%'} borderBottom={1} paddingBottom={1}>
                        <Typography fontWeight="fontWeightMedium" component="h3">
                            Member Access Report
                        </Typography>
                    </Box>
                    {<Loading message={'Loading...'} />}
                    <Grid alignItems={'center'} container textAlign={'left'} paddingY={1}>
                        <Grid item xs={10}>
                            <Stack direction="row" alignItems={'center'} spacing={3}>
                                <Grid item xs={2}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="AHCCCS ID"
                                        value={searchKey}
                                        onChange={(event) => setSearchKey(event?.target?.value)}
                                        placeholder="Enter AHCCCS ID"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel>Date Filter</InputLabel>
                                        <Select
                                            value={dateFilter}
                                            label="Date Filter"
                                            name="Date"
                                            onChange={(e) => setDateFilter(parseInt(e.target.value as any))}
                                        >
                                            {dateFilterList.map((date) => (
                                                <MenuItem key={date.Id} value={date.Id}>
                                                    {date.Value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {dateFilter === TimePeriodFilterEnum.Custom ? (
                                    <React.Fragment>
                                        <Grid item md={2}>
                                            <DesktopDatePicker
                                                label="From Date"
                                                value={fromDate || null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeFromDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={2}>
                                            <DesktopDatePicker
                                                label="To Date"
                                                value={toDate || null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => changeToDate(newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            fetchMemberAccessReport(
                                                searchKey,
                                                dateFilter,
                                                Boolean(fromDate) && dateFilter === TimePeriodFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                                                Boolean(toDate) && dateFilter === TimePeriodFilterEnum.Custom ? new Date(toDate).toISOString() : ''
                                            )
                                        )
                                    }
                                    style={{ minWidth: '120px' }}
                                    variant="contained"
                                >
                                    Search
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={2} marginY={2} display="flex">
                            <Stack direction="row" spacing={3} sx={{ marginLeft: 'auto!important', marginRight: '0' }}>
                                <Button
                                    style={{ minWidth: '120px' }}
                                    onClick={() =>
                                        dispatch(
                                            exportMemberAccessReport(
                                                searchKey,
                                                dateFilter,
                                                Boolean(fromDate) && dateFilter === TimePeriodFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                                                Boolean(toDate) && dateFilter === TimePeriodFilterEnum.Custom ? new Date(toDate).toISOString() : ''
                                            )
                                        )
                                    }
                                    variant="contained"
                                    color="success"
                                >
                                    {downLoading ? 'Downloading..' : 'Export'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid width={'100%'}>
                        <div style={{ height: '75vh', width: '100%' }}>
                            <DataGrid
                                rows={memberAccessData}
                                columns={columns}
                                pagination
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => Math.random()}
                                getRowHeight={() => 'auto'}
                                onRowClick={(params) => {
                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/member-profile`, '_blank');
                                }}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default MemberAccessReport;
