import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import CallingPerformance from '../../../OfficeOnboardingWidgets/CallingPerformance/CallingPerformance';
import CurrentCallList from '../../../OfficeOnboardingWidgets/CurrentCallList/CurrentCallList';
import CallerListDistribution from '../CallerListDistribution/CallerListDistribution';
import CallerHistory from '../CallerHistory/CallerHistory';
import MessageDisplay from '../../../Shared/MessageDisplay';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { OnboarderStatusTabValueEnum } from '../../../../Enum/OnboarderStatusTabValueEnum';

const OnboarderPanel = () => {
    const { selectedOnboarder } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const [callTabs, setCallTabs] = useState(OnboarderStatusTabValueEnum.CallingPerformance);

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
        setCallTabs(parseInt(newValue));
    };

    return selectedOnboarder?.Id ? (
        <>
            <MessageDisplay />
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-around" spacing={1}>
                <Grid item lg={12} md={12}>
                    <Tabs variant="fullWidth" value={callTabs} onChange={handleTabsChange}>
                        <Tab value={OnboarderStatusTabValueEnum.CallingPerformance} label="Calling Performance" />
                        <Tab value={OnboarderStatusTabValueEnum.CallListDistribution} label="Call Queue Distribution" />
                        <Tab value={OnboarderStatusTabValueEnum.CurrentCallList} label="Current Call Queue" />
                        <Tab value={OnboarderStatusTabValueEnum.CallHistory} label="Call History" />
                    </Tabs>
                    {callTabs === OnboarderStatusTabValueEnum.CallingPerformance && (
                        <CallingPerformance
                            onboarderId={selectedOnboarder.Id}
                            onboarderName={selectedOnboarder.Username}
                            onboarderCallGoal={selectedOnboarder.CallsGoal}
                            isAdmin
                        />
                    )}
                    {callTabs === OnboarderStatusTabValueEnum.CallListDistribution && <CallerListDistribution />}{' '}
                    {callTabs === OnboarderStatusTabValueEnum.CurrentCallList && <CurrentCallList />}
                    {callTabs === OnboarderStatusTabValueEnum.CallHistory && <CallerHistory selectedOnboarder={selectedOnboarder} />}{' '}
                </Grid>
            </Grid>
        </>
    ) : (
        <Grid direction="row" alignItems="center" justifyContent="space-evenly" container spacing={1}>
            <Grid item xs={12} marginTop={2}>
                <Typography variant="h4">No Onboarder Selected</Typography>
            </Grid>
        </Grid>
    );
};

export default OnboarderPanel;
