// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Typography } from '@mui/material';
import home from '../../assets/images/home.png';
import greyPin from '../../assets/images/grey_pin.png';

const Map = () => {
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [showIndex, setShowIndex] = useState(0);

    const [defaultCenter, setDefaultCenter] = useState({
        lat: 33.302947,
        lng: -111.803276,
    });
    const mapStyles = {
        height: 'calc(100vh - 200px)',
        width: '100%',
    };

    const { appointmentMaps, useHomeAddress } = useSelector((state: RootState) => state.appointmentMap);
    const { mapAPIKey } = useSelector((state: RootState) => state.shared);

    useEffect(() => {
        if (appointmentMaps.Appointments && appointmentMaps.Appointments.length > 0) {
            var first = appointmentMaps.Appointments[0];
            if (first.Location) {
                setDefaultCenter({ lat: first.Location.lat, lng: first.Location.lng });
            }
        }
    }, [appointmentMaps]);

    const mouseOver = (show: boolean, index: number) => {
        setShowIndex(index);
        setShowInfoWindow(show);
    };

    return (
        // @ts-ignore
        <LoadScript googleMapsApiKey={mapAPIKey}>
            <GoogleMap mapContainerStyle={mapStyles} zoom={11} center={defaultCenter}>
                {appointmentMaps.Appointments?.map((apptMap, index) => {
                    return (
                        // @ts-ignore
                        <Marker
                            onMouseOver={() => {
                                mouseOver(true, index);
                            }}
                            onMouseOut={() => {
                                mouseOver(false, index);
                            }}
                            zIndex={index}
                            opacity={0.8}
                            label={{ text: (index + 1).toString(), color: 'white', fontWeight: 'bold' }}
                            key={apptMap.id}
                            position={apptMap.Location}
                            icon={apptMap.Checked ? greyPin : ''}
                        >
                            {
                                // @ts-ignore
                                showInfoWindow && showIndex === index ? (
                                    <InfoWindow>
                                        <>
                                            <Typography style={{ fontWeight: 'bold' }}>{'(' + (index + 1) + ') ' + apptMap.MemberName}</Typography>
                                            <hr />
                                            <Typography>{apptMap.address}</Typography>
                                            <br />
                                            <Typography>{apptMap.reason}</Typography>
                                        </>
                                    </InfoWindow>
                                ) : null
                            }
                        </Marker>
                    );
                })}
                {useHomeAddress ? (
                    <Marker
                        onMouseOver={() => {
                            mouseOver(true, -1);
                        }}
                        onMouseOut={() => {
                            mouseOver(false, -1);
                        }}
                        zIndex={-1}
                        opacity={0.8}
                        label={{ text: 'PH', color: 'white', fontWeight: 'bold' }}
                        key={'providerHome'}
                        position={appointmentMaps.ProviderHomeLocation}
                        icon={home}
                    >
                        {
                            // @ts-ignore
                            showInfoWindow && showIndex === -1 ? (
                                <InfoWindow>
                                    <>
                                        <Typography style={{ fontWeight: 'bold' }}>{'(PH) Provider Home'}</Typography>
                                    </>
                                </InfoWindow>
                            ) : null
                        }
                    </Marker>
                ) : null}
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
