import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { RootState } from '../../../reducers';
import { fetchHealthPlanList } from '../../../store/shared.slice';
import { fetchMemberHedisReport, exportMemberHedisReport } from '../../../store/memberHedisReport.slice';
import { isDefaultDate } from '../../../utils/common';
import { fetchTeamsName } from '../../../store/teams.slice';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import { parentUrl } from '../../../utils/constants';

const hedisTypeList = [
    { Id: HedisMetricsTypeEnum.BP, Name: HedisMetricsTypeEnum.BpText.toString() },
    { Id: HedisMetricsTypeEnum.ProviderVisits, Name: HedisMetricsTypeEnum.ProviderVisitText.toString() },
    { Id: HedisMetricsTypeEnum.BMI, Name: HedisMetricsTypeEnum.BmiText.toString() },
    { Id: HedisMetricsTypeEnum.BreastCancerScreening, Name: HedisMetricsTypeEnum.BreastCancerText.toString() },
    { Id: HedisMetricsTypeEnum.HgbA1c, Name: HedisMetricsTypeEnum.HgbA1cText.toString() },
    { Id: HedisMetricsTypeEnum.DiabeticEyeExam, Name: HedisMetricsTypeEnum.DiabeticEyeExamText.toString() },
    { Id: HedisMetricsTypeEnum.MedicationReconciliation, Name: HedisMetricsTypeEnum.MedicationReconciliationText.toString() },
    { Id: HedisMetricsTypeEnum.DepressionScreening, Name: HedisMetricsTypeEnum.DepressionScreeningText.toString() },
    { Id: HedisMetricsTypeEnum.DepressionFollowUp, Name: HedisMetricsTypeEnum.DepressionFollowUpText.toString() },
    { Id: HedisMetricsTypeEnum.HgbA1cForBhDx, Name: HedisMetricsTypeEnum.HgbA1cForBhDxText.toString() },
    { Id: HedisMetricsTypeEnum.LipidForBhDx, Name: HedisMetricsTypeEnum.LipidForBhDxText.toString() },
];

const MemberHedisReport = () => {
    const dispatch = useDispatch();
    const allText = 'All';
    const { teams } = useSelector((state: RootState) => state.teams);
    const { memberHedisData } = useSelector((state: RootState) => state.memberHedisReport);
    const { healthPlans, downLoading } = useSelector((state: RootState) => state.shared);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [healthPlan, setHealthPlan] = useState<string>('All');
    const [team, setTeam] = useState<string>('All');
    const [hedisTypes, setHedisTypes] = useState(hedisTypeList.map((a) => a.Name));
    const [hedisMetricStatus, setHedisMetricStatus] = useState<string>('0');
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const filteredTeams = (showInActive ? teams : teams.filter((t) => t.IsActive)) || [];
    const isSelectAll = hedisTypes?.length === hedisTypeList.length;

    const hedisStatusList = [
        { Id: 1, Name: 'Met' },
        { Id: 2, Name: 'Not Met' },
    ];

    const columns: GridColDef[] = [
        {
            field: 'AHCCCSId',
            headerName: 'AHCCCS_ID',
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.AHCCCSId}
                    </Typography>
                );
            },
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 175,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.LastName}
                    </Typography>
                );
            },
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 175,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.FirstName}
                    </Typography>
                );
            },
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.DOB}
                    </Typography>
                );
            },
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 230,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.HealthPlan}
                    </Typography>
                );
            },
        },
        {
            field: 'ChpStaffTeam',
            headerName: 'CHP Staff Team',
            minWidth: 230,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.ChpStaffTeam}
                    </Typography>
                );
            },
        },
        {
            field: 'HedisType',
            headerName: 'Hedis Type',
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {params.row.HedisType}
                    </Typography>
                );
            },
        },
        {
            field: 'DateMet',
            headerName: 'Date Met',
            minWidth: 110,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {isDefaultDate(params.row.DateMet) ? '-' : localDateFormat(new Date(params.row.DateMet))}
                    </Typography>
                );
            },
        },
        {
            field: 'DateNotMet',
            headerName: 'Date Not Met',
            minWidth: 110,
            renderCell: (params) => {
                return (
                    <Typography color={params.row.IsExpired ? 'red' : params.row.IsMetAtThisVisit ? 'green' : '#ed6c02'} fontSize={14}>
                        {isDefaultDate(params.row.DateNotMet) ? '-' : localDateFormat(new Date(params.row.DateNotMet))}
                    </Typography>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchTeamsName());
        dispatch(fetchHealthPlanList());
    }, [dispatch]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
    };

    const handleOnChange = (e: SelectChangeEvent<unknown>) => {
        let selectedValues = e.target.value as string[];
        if (selectedValues.includes(allText) && !isSelectAll) {
            selectedValues = hedisTypeList.map((o) => o.Name);
        } else if (selectedValues.includes(allText)) {
            selectedValues = [];
        }

        setHedisTypeIds(selectedValues);
    };

    const setHedisTypeIds = (selectedIds: string[]) => {
        selectedIds = selectedIds.length > 1 ? selectedIds.filter((s) => s !== allText) : selectedIds;
        setHedisTypes(selectedIds);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box style={{ width: '100%', marginLeft: '8px' }} component={'div'}>
                <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Box width={'100%'} borderBottom={1} paddingBottom={1}>
                        <Typography fontWeight="fontWeightMedium" component="h3">
                            Member Hedis Report
                        </Typography>
                    </Box>
                    {<Loading message={'Loading...'} />}
                    <Grid alignItems={'center'} container textAlign={'left'} paddingY={1}>
                        <Grid item xs={10} lg={10}>
                            <Stack direction="row" alignItems={'center'} spacing={3}>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="Start Date"
                                        value={startDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="End Date"
                                        value={endDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel>Health Plan</InputLabel>
                                    <Select
                                        defaultValue={healthPlan}
                                        value={healthPlan}
                                        label="Health Plan"
                                        onChange={(e) => {
                                            setHealthPlan(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {healthPlans.map((healthPlan) => (
                                            <MenuItem value={healthPlan.Id} key={healthPlan.Id}>
                                                {healthPlan.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel>Teams</InputLabel>
                                    <Select
                                        defaultValue={team}
                                        value={team}
                                        label="Teams"
                                        onChange={(e) => {
                                            setTeam(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                            label="Show Inactive"
                                        />
                                        {filteredTeams.map((team) => (
                                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                                {team.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Hedis Types</InputLabel>
                                    <Select
                                        label="Hedis Types"
                                        value={hedisTypes}
                                        multiple
                                        onChange={handleOnChange}
                                        input={<OutlinedInput label="Hedis Types" />}
                                        renderValue={(selected) => (isSelectAll ? allText : selected.join(', '))}
                                    >
                                        <MenuItem value={allText} className={isSelectAll ? 'Mui-selected' : ''} sx={{ padding: '0', paddingLeft: '10px' }}>
                                            <Checkbox checked={isSelectAll} indeterminate={Boolean(hedisTypes?.length) && !isSelectAll} />
                                            {allText}
                                        </MenuItem>
                                        {hedisTypeList.map((type) => {
                                            return (
                                                <MenuItem key={type.Id} value={type.Name} sx={{ padding: '0', paddingLeft: '10px' }}>
                                                    <Checkbox checked={hedisTypes.indexOf(type.Name) > -1} />
                                                    <ListItemText primary={type.Name} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel>Hedis Status</InputLabel>
                                    <Select
                                        defaultValue={hedisMetricStatus}
                                        value={hedisMetricStatus}
                                        label="Hedis Status"
                                        onChange={(e) => {
                                            setHedisMetricStatus(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        {hedisStatusList.map((hedis) => (
                                            <MenuItem value={hedis.Id} key={hedis.Id}>
                                                {hedis.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    onClick={() =>
                                        dispatch(fetchMemberHedisReport(startDate, endDate, healthPlan, team, hedisTypes, hedisMetricStatus, showInActive))
                                    }
                                    style={{ minWidth: '120px' }}
                                    variant="contained"
                                >
                                    Search
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={10} lg={2} marginY={2} display="flex">
                            <Stack direction="row" spacing={3} sx={{ marginLeft: 'auto!important', marginRight: '0' }}>
                                <Button
                                    style={{ minWidth: '120px' }}
                                    onClick={() =>
                                        dispatch(exportMemberHedisReport(startDate, endDate, healthPlan, team, hedisTypes, hedisMetricStatus, showInActive))
                                    }
                                    variant="contained"
                                    color="success"
                                >
                                    {downLoading ? 'Downloading..' : 'Export'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid width={'100%'}>
                        <div style={{ height: '75vh', width: '100%' }}>
                            <DataGrid
                                rows={memberHedisData}
                                columns={columns}
                                pagination
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => Math.random()}
                                getRowHeight={() => 'auto'}
                                onRowClick={(params) => {
                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/member-profile`, '_blank');
                                }}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default MemberHedisReport;
