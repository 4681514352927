import { Eventcalendar, MbscCalendarEvent, Popup } from '@mobiscroll/react'; /* or import any other component */
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ICallItem from '../../../Models/OnboardingWorkflow/CallItem.model';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { appointmentStatusMap } from '../../../utils/mappings';
import MessageDisplay from '../../Shared/MessageDisplay';
import EventContent from '../EventContent';
import EventLabel from '../EventLabel';
import CalendarEventEditModal from '../Modals/CalendarEventEditModal';
import { cancelRecurringInstanceEvent, getMemberSchedule, mapEventData, updateEvent } from '../Services/CommonCalendarServices';
import EventTooltip from '../Tooltips/EventTooltip';
import { CalendarEventData } from '../types/CalendarEventData';
import { setShowMessage } from '../../../store/shared.slice';
import { useDispatch } from 'react-redux';
import RescheduleEventModal from '../Modals/RescheduleEventModal';
import { RescheduledNonRecurrenceDto } from '../../../Models/Scheduling/RescheduledNonRecurrenceDto.model';
import { RescheduledRecurrenceDto } from '../../../Models/Scheduling/RescheduledRecurrenceDto.model';
import { CreatedInstanceDto } from '../../../Models/Scheduling/CreatedInstanceDto.model';

interface IOnboardingCalendarProps {
    callItem: ICallItem;
    canCreate?: boolean;
    canEdit?: boolean;
}

const OnboardingCalendar: React.FC<IOnboardingCalendarProps> = (props) => {
    const {
        callItem: { AppointmentDateTime = new Date(), MemberId },
    } = props;
    const dispatch = useDispatch();
    const [myEvents, setMyEvents] = useState([]);
    const [isEventEditorOpen, setIsEventEditorOpen] = useState<boolean>(false);
    const [mySelectedDate, setSelectedDate] = React.useState(AppointmentDateTime);
    const [monthBounds, setMonthBounds] = React.useState<{ firstDate: Date; lastDate: Date } | undefined>({
        firstDate: new Date(),
        lastDate: new Date(),
    });
    const [hoveredEvent, setHoveredEvent] = useState<CalendarEventData | null>(null);
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
    console.log({ AppointmentDateTime, monthBounds });
    const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false);
    const [isRecurringRescheduleModalOpen, setIsRecurringRescheduleModalOpen] = useState<boolean>(false);

    const handleDeleteEventSuccess = (eventId: string) => {
        const newEvents = myEvents.filter((item) => item.Id !== eventId);
        setMyEvents(newEvents);
        console.log('handleDeleteEventSuccess', newEvents, eventId);
    };

    const handleUpdateEventSuccess = (event: Appointment | CalendarEventData) => {
        setMyEvents((currentEvents) => {
            return currentEvents.map((item) => {
                if (item.Id === event.Id) {
                    // TODO: clean this up so we don't have to map data every time we set the events
                    return mapEventData(event);
                } else {
                    return item;
                }
            });
        });
    };

    const handleCreateEventSuccess = (event: Appointment, rescheduledEvent?: CalendarEventData) => {
        setMyEvents((currentEvents) => {
            let newEvents = [...currentEvents, mapEventData(event)];
            if (rescheduledEvent !== undefined) {
                newEvents = newEvents.map((item) => {
                    if (item.Id === rescheduledEvent.id) {
                        rescheduledEvent.RescheduledAppointmentId = event.Id;
                        if (
                            rescheduledEvent.status !== appointmentStatusMap.Cancelled.value ||
                            rescheduledEvent.status !== appointmentStatusMap.NoCallNoShow.value
                        ) {
                            rescheduledEvent.status = appointmentStatusMap.Cancelled.value;
                        }
                        // TODO: clean this up so we don't have to map data every time we set the events
                        return mapEventData(rescheduledEvent);
                    } else {
                        return item;
                    }
                });
            }
            return newEvents;
        });
    };

    const handleSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const handleCloseEventEditor = () => {
        setIsEventEditorOpen(false);
    };
    const handleEventClick = (args: MbscCalendarEvent) => {
        const eventInfo = args.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setHoveredEvent(eventInfo);
        setAnchor(args.domEvent.target.parentElement);
        setIsPopupOpen(true);
    };

    const handleEventEdit = (eventData: CalendarEventData) => {
        setSelectedEvent(eventData);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);
    };

    const handleEventCreate = (event: MbscCalendarEvent) => {
        const eventInfo = event.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);

        // We will add the event manually, returning false makes mobiscroll not add a placeholder.
        return false;
    };

    const handleRescheduleEventClick = (eventData: CalendarEventData) => {
        if (eventData.recurring) {
            setIsRecurringRescheduleModalOpen(true);
            return;
        }

        if (eventData.status !== appointmentStatusMap.Cancelled.value && eventData.status !== appointmentStatusMap.NoCallNoShow.value) {
            eventData.status = appointmentStatusMap.Cancelled.value;
        }

        const newEvent: Partial<CalendarEventData> & { IsRescheduling: boolean } = {
            ...eventData,
            Id: undefined,
            status: appointmentStatusMap.Unconfirmed.value,
            AppointmentStatus: appointmentStatusMap.Unconfirmed.value,
            OriginalAppointmentId: eventData.Id,
            IsRescheduling: true,
        };

        setSelectedEvent(newEvent as any);
        setIsEventEditorOpen(true);
    };

    const handleCreateRecurrenceInstance = async (createdInstanceDto: CreatedInstanceDto) => {
        const { CreatedInstanceAppointment, OriginalRecurrenceAppointment } = createdInstanceDto;
        const mappedNewEvent = mapEventData(CreatedInstanceAppointment);
        const mappedOriginalEvent = mapEventData(OriginalRecurrenceAppointment);
        let newEvents = [
            mappedNewEvent,
            ...myEvents.map((item) => {
                if (item.Id === mappedOriginalEvent.Id) {
                    return mappedOriginalEvent;
                } else {
                    return item;
                }
            }),
        ];
        setMyEvents(newEvents);
    };
    const handleCancelEventClick = async (eventData: CalendarEventData) => {
        setIsBackdropOpen(true);
        if (eventData.recurring) {
            const response = await cancelRecurringInstanceEvent(eventData.original as Appointment, new Date(eventData.start));
            handleCreateRecurrenceInstance(response.data);
            setIsBackdropOpen(false);
            return;
        }
        eventData.status = appointmentStatusMap.Cancelled.value;
        try {
            const response = await updateEvent(eventData);
            if (response.data) {
                handleUpdateEventSuccess(response.data);
            }
        } catch (error) {
            let errorMessage = 'An error in loading the calendar has occurred. A response was given but data was not received with it.';
            if (error?.response?.data) {
                errorMessage = `${error}. \n\nReason: ${error.response.data}`;
            }
            setMyEvents([]);
            dispatch(setShowMessage(true, errorMessage, 'error'));
        }
        setIsBackdropOpen(false);
        handleUpdateEventSuccess(eventData);
    };

    const getMonthBounds = useCallback(
        (firstDay: Date, lastDay: Date, originalMonthBounds: { firstDate: Date; lastDate: Date }): { newFirstDate: Date; newLastDate: Date } => {
            const newBoundFirstDateTime = firstDay.getTime();
            const newBoundLastDateTime = lastDay.getTime();
            const monthBoundFirstDateTime = originalMonthBounds.firstDate.getTime();
            const monthBoundLastDateTime = originalMonthBounds.lastDate.getTime();
            if (newBoundFirstDateTime !== monthBoundFirstDateTime || newBoundLastDateTime !== monthBoundLastDateTime) {
                return { newFirstDate: firstDay, newLastDate: lastDay };
            } else return { newFirstDate: originalMonthBounds.firstDate, newLastDate: originalMonthBounds.lastDate };
        },
        []
    );

    const updateBounds = useCallback(
        (firstDay: Date, lastDay: Date) => {
            const { newFirstDate, newLastDate } = getMonthBounds(firstDay, lastDay, monthBounds);
            setIsPopupOpen(false);
            setMonthBounds({ firstDate: newFirstDate, lastDate: newLastDate });
        },
        [getMonthBounds, monthBounds]
    );

    useEffect(() => {
        const { firstDate, lastDate } = monthBounds;
        const startDateMinusOneMonth = new Date(firstDate);
        startDateMinusOneMonth.setMonth(startDateMinusOneMonth.getMonth() - 1);
        const lastDatePlusOneMonth = new Date(lastDate);
        lastDatePlusOneMonth.setMonth(lastDatePlusOneMonth.getMonth() + 1);

        if (firstDate.getTime() !== lastDate.getTime()) {
            const fetchSchedule = async () => {
                setIsBackdropOpen(true);
                try {
                    const response = await getMemberSchedule(MemberId, startDateMinusOneMonth, lastDatePlusOneMonth);
                    if (response.status === 200) {
                        const data = response.data;
                        setMyEvents(data.map(mapEventData));
                        setIsBackdropOpen(false);
                    }
                } catch (error) {
                    setIsBackdropOpen(false);
                    console.error('Error fetching schedule', error);
                }
            };
            fetchSchedule();
        }
    }, [MemberId, monthBounds]);

    return (
        <Stack spacing={1}>
            {selectedEvent !== undefined && selectedEvent !== null ? (
                <>
                    <CalendarEventEditModal
                        open={isEventEditorOpen}
                        onSuccess={() => {
                            setIsEventEditorOpen(false);
                        }}
                        onUpdateEventSuccess={handleUpdateEventSuccess}
                        eventList={myEvents as any}
                        onCreateEventSuccess={handleCreateEventSuccess}
                        onRescheduleEvent={handleUpdateEventSuccess}
                        setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                        onClose={handleCloseEventEditor}
                        eventData={selectedEvent}
                        memberId={MemberId}
                    />
                    <RescheduleEventModal
                        eventData={selectedEvent}
                        setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                        open={isRecurringRescheduleModalOpen}
                        onClose={() => {
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                        onRescheduleSuccess={(rescheduledAppointment: RescheduledNonRecurrenceDto) => {
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                        onRescheduleRecurrenceSuccess={(rescheduledRecurrence: RescheduledRecurrenceDto) => {
                            const { RescheduledAppointment, OriginalInstanceAppointment, OriginalRecurrenceAppointment } = rescheduledRecurrence;
                            const newEvents = myEvents
                                .map((item) => {
                                    if (item.Id === OriginalRecurrenceAppointment.Id) {
                                        return mapEventData(OriginalRecurrenceAppointment);
                                    } else {
                                        return item;
                                    }
                                })
                                .concat([mapEventData(RescheduledAppointment), mapEventData(OriginalInstanceAppointment)]);
                            setMyEvents(newEvents);
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                    />
                </>
            ) : null}
            <MessageDisplay />
            <Backdrop open={isBackdropOpen} sx={{ zIndex: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Eventcalendar
                data={myEvents}
                clickToCreate={'double'}
                renderLabelContent={(event) => <EventLabel event={event} />}
                renderEventContent={(event) => <EventContent event={event} />}
                theme="material"
                height={'60vh'}
                width={'100%'}
                themeVariant="light"
                view={{ calendar: { labels: true, type: 'week' } }}
                selectedDate={mySelectedDate}
                onSelectedDateChange={handleSelectedDateChange}
                onEventClick={handleEventClick}
                onPageLoading={({ firstDay, lastDay }, inst) => {
                    updateBounds(firstDay, lastDay);
                }}
                onEventCreate={handleEventCreate}
            />
            <Popup
                display="anchored"
                isOpen={isPopupOpen}
                anchor={anchor}
                touchUi={false}
                showOverlay={false}
                contentPadding={false}
                closeOnOverlayClick={false}
                cssClass="md-tooltip"
                style={{ zIndex: 99 }}
            >
                <EventTooltip
                    hoveredEvent={hoveredEvent}
                    onMouseEnter={() => {}}
                    onMouseLeave={() => {}}
                    onEventCancelClick={handleCancelEventClick}
                    onDeleteEventSuccess={handleDeleteEventSuccess}
                    onEventRescheduleClick={handleRescheduleEventClick}
                    closePopup={() => setIsPopupOpen(false)}
                    onEventEditClick={(eventData: CalendarEventData) => {
                        handleEventEdit(eventData);
                        setIsPopupOpen(false);
                    }}
                />
            </Popup>
        </Stack>
    );
};

export default OnboardingCalendar;
