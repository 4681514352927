import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Provider } from '../../Models/Provider/Provider.model';
import IProviderSelectOption from '../../Models/Provider/ProviderSelectOption.model';
import Appointment from '../../Models/Scheduling/Appointment.model';
import { apiString } from '../../utils/constants';
import { IOption } from '../interfaces/IOption';
import { CalendarLoadModeEnum } from './Enums/CalendarLoadModeEnum';
import {
    getAllProvidersSchedulesInDateRange,
    getCurrentUsersSchedule,
    getExternalProviderScheduleByNameAndDateRange,
    getMemberAndProviderScheduleById,
    getMemberSchedule,
    getSelectedProviderSchedules,
} from './Services/CommonCalendarServices';
import { scheduleOptionsList, scheduleOptionsMap } from './mappings/ScheduleOptionsMap';
import { LoadableScheduleOptionMap } from './types/LoadableScheduleOptionMap';
import { ScheduleOption } from './types/ScheduleOption';

export interface ILoadByModeDropdownProps {
    startDate: Date;
    endDate: Date;
    memberId?: string;
    defaultScheduleLoadOption: ScheduleOption;
    onLoadSuccess: (events: Appointment[]) => void;
    onEventsLoading?: () => void;
    onLoadFailure?: (error: any) => void;
    optionsFilter?: (option: IOption) => boolean;
}

const LoadByModeDropdown = (props: ILoadByModeDropdownProps) => {
    const { startDate, endDate, memberId, defaultScheduleLoadOption, onLoadSuccess, onEventsLoading, onLoadFailure, optionsFilter } = props;
    const [scheduleOption, setScheduleOption] = useState<IOption>(defaultScheduleLoadOption);
    const [calendarModeInputValue, setCalendarModeInputValue] = useState<string>('');
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [externalProviderName, setExternalProviderName] = useState<string>('');
    const [selectedProviderOptions, setSelectedProviderOptions] = useState<IProviderSelectOption[]>([]);

    const tryLoadEvents = async (fetchEvents: () => Promise<AxiosResponse<Appointment[]>>) => {
        try {
            onEventsLoading?.();
            const response = await fetchEvents();
            if (response.data) {
                onLoadSuccess(response.data);
            }
        } catch (error) {
            onLoadFailure?.(error);
            console.error(error);
        }
    };

    const loadableScheduleOptionsMap: LoadableScheduleOptionMap = useMemo(() => {
        // Temporary measure, this allows us to fetch all recurring appointments
        const startDateMinusOneYear = new Date(startDate);
        startDateMinusOneYear.setFullYear(startDateMinusOneYear.getFullYear() - 1);
        const startDateMinusOneMonth = new Date(startDate);
        startDateMinusOneMonth.setMonth(startDateMinusOneMonth.getMonth() - 1);
        const lastDatePlusOneMonth = new Date(endDate);
        lastDatePlusOneMonth.setMonth(lastDatePlusOneMonth.getMonth() + 1);

        return Object.entries(scheduleOptionsMap).reduce((acc, [key, option]) => {
            acc[key as CalendarLoadModeEnum] = {
                ...option,
                load: async () => {
                    switch (key) {
                        case CalendarLoadModeEnum.ByCurrentUser:
                            await tryLoadEvents(() => getCurrentUsersSchedule(startDateMinusOneYear, lastDatePlusOneMonth));
                            break;
                        case CalendarLoadModeEnum.ByMember:
                            if (memberId) {
                                await tryLoadEvents(() => getMemberSchedule(memberId, startDateMinusOneMonth, lastDatePlusOneMonth));
                            }
                            break;
                        case CalendarLoadModeEnum.ByProviders:
                            if (selectedProviderOptions.length > 0) {
                                await tryLoadEvents(() =>
                                    getSelectedProviderSchedules(
                                        startDateMinusOneYear,
                                        lastDatePlusOneMonth,
                                        selectedProviderOptions.map((provider) => provider.ProviderId)
                                    )
                                );
                            }
                            break;
                        case CalendarLoadModeEnum.ByAllProviders:
                            await tryLoadEvents(() => getAllProvidersSchedulesInDateRange(startDateMinusOneYear, lastDatePlusOneMonth));
                            break;
                        case CalendarLoadModeEnum.ByExternalProvider:
                            // This case shouldn't be executed at any other point than by the user pressing enter.
                            // Keep this case here for dropdown ui, but execute the call for the external provider in the onKeyDown event
                            // on the text field.
                            console.log(`Load by external provider: ${externalProviderName}`);
                            break;
                        case CalendarLoadModeEnum.ByMemberAndProviders:
                            if (memberId) {
                                await tryLoadEvents(() =>
                                    getMemberAndProviderScheduleById(
                                        memberId,
                                        startDateMinusOneYear,
                                        lastDatePlusOneMonth,
                                        selectedProviderOptions.map((provider) => provider.ProviderId)
                                    )
                                );
                            }
                            break;
                        default:
                            const exhaustiveCheck: string = key;
                            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
                    }
                },
            };
            return acc;
        }, {} as LoadableScheduleOptionMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, externalProviderName, memberId, selectedProviderOptions, startDate]);

    useEffect(() => {
        const option = loadableScheduleOptionsMap[scheduleOption.value];
        console.log({ option, loadableScheduleOptionsMap, scheduleOption, startDate, endDate });
        option?.load();
    }, [loadableScheduleOptionsMap, scheduleOption, startDate, endDate]);

    let providerSelection = null;
    if (scheduleOption.value === CalendarLoadModeEnum.ByProviders || scheduleOption.value === CalendarLoadModeEnum.ByMemberAndProviders) {
        providerSelection = (
            <Autocomplete
                fullWidth
                id="calendar-internal-provider-selection"
                multiple={true}
                options={providerOptions}
                isOptionEqualToValue={(option, value) => {
                    return option.ProviderId === value.ProviderId;
                }}
                renderInput={(params) => <TextField {...params} label="Selected Providers" />}
                value={selectedProviderOptions}
                onChange={async (event: any, newValue: any) => {
                    setSelectedProviderOptions(newValue);
                }}
                inputValue={providerInputValue}
                onInputChange={(event, value) => {
                    setProviderInputValue(value);
                }}
                getOptionLabel={(option: any) => option.ProviderName}
            />
        );
    } else if (scheduleOption.value === CalendarLoadModeEnum.ByExternalProvider) {
        providerSelection = (
            <TextField
                fullWidth
                label="External Provider Name"
                id="calendar-external-provider-selection"
                value={externalProviderName}
                helperText="Press enter to load the schedule"
                onKeyDown={async (event: any) => {
                    if (event.keyCode === 13) {
                        await tryLoadEvents(() => getExternalProviderScheduleByNameAndDateRange(startDate, endDate, externalProviderName));
                    }
                }}
                onChange={(event: any) => {
                    setExternalProviderName(event.target.value);
                }}
            />
        );
    }

    useEffect(() => {
        axios.get(`${apiString}/Provider/getactiveproviders`).then((response) => {
            if (response.status === 200) {
                const responseOptions = response.data.map((provider: Provider) => ({
                    ProviderId: provider.Id,
                    ProviderName: `${provider.LastName}, ${provider.FirstName}`,
                }));
                setProviderOptions(responseOptions);
            }
        });
    }, []);

    const filteredOptions = useMemo(() => {
        if (optionsFilter) return scheduleOptionsList.filter(optionsFilter);
        return scheduleOptionsList;
    }, [optionsFilter]);

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack spacing={3}>
                    <Autocomplete
                        fullWidth
                        disableClearable={true}
                        id="calendar-mode-selection"
                        multiple={false}
                        options={filteredOptions}
                        isOptionEqualToValue={(option, value: any) => {
                            return option.value === value;
                        }}
                        renderInput={(params) => <TextField {...params} label="Schedule Selection" />}
                        value={scheduleOption}
                        onChange={(event: any, newValue: any) => {
                            setScheduleOption(newValue);
                        }}
                        inputValue={calendarModeInputValue}
                        onInputChange={(event, value) => {
                            setCalendarModeInputValue(value);
                        }}
                        getOptionLabel={(option) => {
                            return (option as IOption).label;
                        }}
                    />
                    {providerSelection}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default LoadByModeDropdown;
