import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import OpenPopulationDto from '../../../../Models/AdminOnboarding/OpenPopulationDto.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { CallListDistributionFilterTypeEnum } from '../../../../Enum/CallListDistributionFilterTypeEnum';
interface ICallListDistributionBoxProps {
    pop: OpenPopulationDto;
    index: number;
    handleChange: (event: any, popId: string) => void;
}
const PopulationBox = (props: ICallListDistributionBoxProps) => {
    const { pop, handleChange } = props;
    const { popError, zipCodeGroups, zipError } = useSelector((state: RootState) => state.adminOnboardingSlice);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <Grid item xs={3}>
            <Paper sx={{ p: 2 }} elevation={pop.Limit ? 10 : 0} variant={pop.Limit ? 'elevation' : 'outlined'}>
                {pop.LimitType === CallListDistributionFilterTypeEnum.ZipCodeGroup ? (
                    <Tooltip
                        title={
                            <Grid container alignItems="center" spacing={1}>
                                {pop.SelectedZipCodes.map((code, index) => (
                                    <Grid item key={code + '_' + index}>
                                        <Chip sx={{ backgroundColor: 'white' }} label={code} />
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    >
                        <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="h6">
                            {pop.PopulationName}
                        </Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="h6">
                        {pop.PopulationName}
                    </Typography>
                )}
                <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.OpenMemberCount} Undetermined`}</Typography>
                {pop.LimitType === CallListDistributionFilterTypeEnum.Population ? (
                    <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.FuhUndCount} FUH-Und`}</Typography>
                ) : null}

                <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.ScheduledMemberCount} Scheduled`}</Typography>

                <Stack spacing={2} marginTop={2} sx={{ textAlign: 'left' }}>
                    <TextField
                        variant="outlined"
                        error={(popError && pop.Limit > 0) || (zipError && pop.Limit > 0)}
                        helperText={(popError && pop.Limit > 0) || (zipError && pop.Limit > 0) ? 'Total Call Limits exceed maximum call list length' : ''}
                        sx={{ marginY: 1 }}
                        label={'Limit'}
                        name="Limit"
                        type="number"
                        value={pop.Limit || ''}
                        onChange={(event: any) => {
                            handleChange(event, pop.PopulationId);
                        }}
                    />
                    {pop.LimitType === CallListDistributionFilterTypeEnum.Population ? (
                        <FormControl fullWidth>
                            <InputLabel id="zip-codes-label">Zip Codes</InputLabel>
                            <Select
                                labelId="zip-codes-label"
                                multiple
                                value={pop.SelectedZipCodes || []}
                                name="SelectedZipCodes"
                                onChange={(event: any) => {
                                    handleChange(event, pop.PopulationId);
                                }}
                                input={<OutlinedInput id="select-multiple-chip" label="Zip Codes" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value, index) => (
                                            <Chip key={value + '_' + index} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {pop.PopulationZipCodes?.map((zip, index) => {
                                    return (
                                        <MenuItem key={`${zip}_${index}`} value={zip.ZipCode}>
                                            {`${zip.ZipCode} (${zip.ZipCodeCount})`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    ) : null}

                    {pop.LimitType === CallListDistributionFilterTypeEnum.Population ? (
                        <FormControl fullWidth>
                            <InputLabel>Zip Code Groups</InputLabel>
                            <Select
                                multiple
                                value={pop.ZipCodeGroupsList || []}
                                label="Zip Code Groups"
                                name="ZipCodeGroupsList"
                                onChange={(event: any) => {
                                    handleChange(event, pop.PopulationId);
                                }}
                            >
                                {zipCodeGroups.map((group) => (
                                    <MenuItem key={group.Id} value={group.Id}>
                                        {group.PopulationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                </Stack>
            </Paper>
        </Grid>
    );
};

export default PopulationBox;
