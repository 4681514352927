import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboarderSettings } from '../../../Models/AdminOnboarding/OnboarderSettings.model';
import IOnboarderStatus, { OnboarderStatus } from '../../../Models/OfficeOnboarding/OnboarderStatus.model';
import { RootState } from '../../../reducers';
import { fetchOnboarderStatuses, saveOnboarderSettings } from '../AdminOnboardingService';

import OnboarderPanel from './OnboarderPanel/OnboarderPanel';
import OnboarderStatusControlBar from './OnboarderPanel/OnboarderStatusControlBar';
import { fetchCallListLimits, fetchCallListZipCodeLimits, setSelectedOnboarder } from '../../../store/adminOnboarding.slice';
import { Paper } from '@mui/material';
import { setOnboarderSettings } from '../../../store/onboardingWorkflow.slice';
import { setShowMessage } from '../../../store/shared.slice';

interface OnboarderStatusesProps {
    viewing: boolean;
}

const OnboarderStatuses = (props: OnboarderStatusesProps) => {
    const { popError, selectedOnboarder } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const { onboarderSettings } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const dispatch = useDispatch();
    const [onboarderStatuses, setOnboarderStatuses] = useState<IOnboarderStatus[]>([]);
    const [saveButtonLabel, setSaveButtonLabel] = useState<string>('Save Defaults');
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [callsGoalValue, setCallsGoalValue] = useState<number | null>(0);
    const [callsGoalLabel, setCallsGoalLabel] = useState<string>('Default Calls Goal');
    const [callsGoalHelperText, setCallsGoalHelperText] = useState<string>('');
    const [callsListLengthValue, setCallsListLengthValue] = useState<number | null>(0);
    const [callsListLengthLabel, setCallsListLengthLabel] = useState<string>('Default List Length');
    const [callsListLengthHelperText, setCallsListLengthHelperText] = useState<string>('');

    const [assignmentFrequencyValue, setAssignmentFrequencyValue] = useState<number | null>(0);
    const [maxAssignmentsValue, setMaxAssignmentsValue] = useState<number | null>(0);

    const [fuhfumAssignmentFrequencyValue, setFuhfumAssignmentFrequencyValue] = useState<number | null>(0);
    const [fuhfumMaxAssignmentsValue, setFuhfumMaxAssignmentsValue] = useState<number | null>(0);

    // onChange Handlers
    const onAutocompleteChange = (event: React.ChangeEvent<{}>, value: IOnboarderStatus) => {
        if (value?.Id) {
            dispatch(setSelectedOnboarder(value));
        } else {
            dispatch(setSelectedOnboarder(new OnboarderStatus()));
        }
    };

    const onCallsGoalChange = (event: any) => setCallsGoalValue(event.target.value);
    const onCallsListLengthChange = (event: any) => setCallsListLengthValue(event.target.value);

    const onAssignmentFrequencyChange = (event: any) => {
        setAssignmentFrequencyValue(event.target.value);
    };
    const onMaxAssignmentsChange = (event: any) => {
        setMaxAssignmentsValue(event.target.value);
    };

    const onFuhfumAssignmentFrequencyChange = (event: any) => {
        setFuhfumAssignmentFrequencyValue(event.target.value);
    };

    const onFuhfumMaxAssignmentsChange = (event: any) => {
        setFuhfumMaxAssignmentsValue(event.target.value);
    };

    const doSaveButtonClick = useCallback(async () => {
        setSaving(true);

        let newSettings;
        if (selectedOnboarder.Id) {
            newSettings = new OnboarderSettings();
            newSettings.OnboarderId = selectedOnboarder.Id;
            newSettings.DefaultCallsGoal = callsGoalValue;
            newSettings.DefaultListLength = callsListLengthValue;
            newSettings.DefaultAssignmentFrequency = assignmentFrequencyValue;
            newSettings.DefaultFuhfumAssignmentFrequency = fuhfumAssignmentFrequencyValue;
            newSettings.DefaultMaxAssignments = maxAssignmentsValue;
            newSettings.DefaultFuhfumMaxAssignments = fuhfumMaxAssignmentsValue;
        } else {
            newSettings = Object.assign({}, onboarderSettings);
            newSettings.DefaultCallsGoal = callsGoalValue;
            newSettings.DefaultListLength = callsListLengthValue;
            newSettings.DefaultAssignmentFrequency = assignmentFrequencyValue;
            newSettings.DefaultFuhfumAssignmentFrequency = fuhfumAssignmentFrequencyValue;
            newSettings.DefaultMaxAssignments = maxAssignmentsValue;
            newSettings.DefaultFuhfumMaxAssignments = fuhfumMaxAssignmentsValue;
            dispatch(setOnboarderSettings(newSettings));
        }
        const response = await saveOnboarderSettings(newSettings);
        if (response.status === 200) {
            dispatch(setShowMessage(true, 'Onboarder Settings Saved', 'success'));
            if (selectedOnboarder.Id) {
                let newOnboarders = [...onboarderStatuses];
                let newOnboarder = Object.assign({}, selectedOnboarder);
                const index = newOnboarders.map((status) => status.Id).indexOf(newOnboarder.Id);
                newOnboarder.CallsGoal = newSettings.DefaultCallsGoal ?? onboarderSettings.DefaultCallsGoal;
                newOnboarder.CallListLength = newSettings.DefaultListLength ?? onboarderSettings.DefaultListLength;
                newOnboarders[index] = newOnboarder;
                dispatch(setSelectedOnboarder(newOnboarder));
                setOnboarderStatuses(newOnboarders);
            }
        } else {
            dispatch(setShowMessage(true, 'Error Saving Settings', 'error'));
        }

        setSaving(false);
    }, [
        callsGoalValue,
        callsListLengthValue,
        onboarderSettings,
        dispatch,
        onboarderStatuses,
        selectedOnboarder,
        assignmentFrequencyValue,
        fuhfumAssignmentFrequencyValue,
        maxAssignmentsValue,
        fuhfumMaxAssignmentsValue,
    ]);

    useEffect(() => {
        if (saving || popError) {
            setSaveButtonDisabled(true);
        } else {
            setSaveButtonDisabled(false);
        }
    }, [saving, popError]);

    useEffect(() => {
        dispatch(setSelectedOnboarder(new OnboarderStatus()));
    }, [props.viewing, dispatch]);

    useEffect(() => {
        fetchOnboarderStatuses().then((response) => {
            if (response.status === 200) {
                const onboardersList = response.data.sort((a, b) => a.Username.localeCompare(b.Username));
                setOnboarderStatuses(onboardersList);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOnboarder.Id) {
            setCallsGoalLabel(`Calls Goal`);
            setCallsListLengthLabel(`List Length`);
            setCallsGoalHelperText(selectedOnboarder.CallsGoal ? '' : 'Using Default');
            setCallsListLengthHelperText(selectedOnboarder.CallListLength ? '' : 'Using Default');
            setCallsGoalValue(selectedOnboarder.CallsGoal ?? onboarderSettings.DefaultCallsGoal);
            setCallsListLengthValue(selectedOnboarder.CallListLength ?? onboarderSettings.DefaultListLength);
            setSaveButtonLabel(`Save Goals`);
        } else {
            setCallsGoalLabel('Default Calls Goal');
            setCallsListLengthLabel('Default List Length');
            setCallsGoalHelperText('');
            setCallsListLengthHelperText('');
            setCallsGoalValue(onboarderSettings.DefaultCallsGoal);
            setCallsListLengthValue(onboarderSettings.DefaultListLength);
            setSaveButtonLabel(`Save Default Settings`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOnboarder]);

    useEffect(() => {
        setAssignmentFrequencyValue(onboarderSettings.DefaultAssignmentFrequency);
        setMaxAssignmentsValue(onboarderSettings.DefaultMaxAssignments);
        setFuhfumAssignmentFrequencyValue(onboarderSettings.DefaultFuhfumAssignmentFrequency);
        setFuhfumMaxAssignmentsValue(onboarderSettings.DefaultFuhfumMaxAssignments);
    }, [
        onboarderSettings.DefaultAssignmentFrequency,
        onboarderSettings.DefaultFuhfumAssignmentFrequency,
        onboarderSettings.DefaultMaxAssignments,
        onboarderSettings.DefaultFuhfumMaxAssignments,
    ]);

    useEffect(() => {
        if (selectedOnboarder.Id) {
            dispatch(fetchCallListLimits(selectedOnboarder.Id));
            dispatch(fetchCallListZipCodeLimits(selectedOnboarder.Id));
        }
    }, [selectedOnboarder.Id, dispatch]);

    return (
        <Paper elevation={3} style={{ padding: '10px' }}>
            <OnboarderStatusControlBar
                autoCompleteOnChange={onAutocompleteChange}
                autocompleteOptions={onboarderStatuses}
                autocompleteInputValue={selectedOnboarder.Username}
                autocompleteHelperText={''}
                saveButtonLabel={saveButtonLabel}
                callsGoalFieldLabel={callsGoalLabel}
                callsGoalFieldValue={callsGoalValue}
                callsGoalFieldHelperText={callsGoalHelperText}
                callsGoalFieldOnChange={onCallsGoalChange}
                callsListFieldValue={callsListLengthValue}
                callsListFieldLabel={callsListLengthLabel}
                callsListFieldHelperText={callsListLengthHelperText}
                callsListFieldOnChange={onCallsListLengthChange}
                onAssignmentFrequencyChange={onAssignmentFrequencyChange}
                onMaxAssignmentsChange={onMaxAssignmentsChange}
                saving={saving}
                doSaveButtonClick={doSaveButtonClick}
                saveButtonDisabled={saveButtonDisabled}
                assignmentFrequencyValue={assignmentFrequencyValue}
                maxAssignmentsValue={maxAssignmentsValue}
                onboarderSettings={onboarderSettings}
                onFuhfumAssignmentFrequencyChange={onFuhfumAssignmentFrequencyChange}
                onFuhfumMaxAssignmentsChange={onFuhfumMaxAssignmentsChange}
                fuhfumAssignmentFrequencyValue={fuhfumAssignmentFrequencyValue}
                fuhfumMaxAssignmentsValue={fuhfumMaxAssignmentsValue}
            />
            <OnboarderPanel />
        </Paper>
    );
};

export default OnboarderStatuses;
