import Population from '../../Models/Population/Population.model';
import IOnboarderSettings from '../../Models/AdminOnboarding/OnboarderSettings.model';
import { apiString as api } from '../../utils/constants';
import axios from 'axios';
import CallLimitParams from '../../Models/AdminOnboarding/CallLimitParams.model';

export const fetchPopulations = async () => {
    const response = await axios.get(`${api}/population/GetPopulations`);
    return response;
};
export const fetchOnboardingExcelReport = async () => {
    const response = await axios.get(`${api}/onboarding/GetOnboardingReport`);
    return response;
};
export const fetchUnreachedOnboardingCallsExcelReport = async (unreachedOnboardingReport: any) => {
    const response = await axios.post(`${api}/onboarding/getunreachedonboardingcallsreport`, unreachedOnboardingReport);
    return response;
};
export const savePopulationGoals = async (populations: Population[]) => {
    const response = await axios.post(`${api}/population/UpdatePopulations`, populations);
    return response;
};
export const fetchOnboarderStatuses = async () => {
    const response = await axios.get(`${api}/onboarding/getonboardingproviders`);
    return response;
};
export const fetchOpenPopulations = async () => {
    const response = await axios.get(`${api}/population/GetOpenPopulations`);
    return response;
};
export const saveCallListLimits = async (callLimitParams: CallLimitParams) => {
    const response = await axios.post(`${api}/onboarding/SaveCallListLimits`, callLimitParams);
    return response;
};
export const saveOnboarderSettings = async (settings: IOnboarderSettings) => {
    const response = await axios.post(`${api}/onboarding/SaveOnboarderSettings`, settings);
    return response;
};
