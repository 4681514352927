export enum HedisMetricsTypeEnum {
    BP = 1,
    BpDescription = 'BP',
    BpText = 'BP',
    ProviderVisits = 2,
    ProviderVisitsDescription = 'AdultHealth',
    ProviderVisitText = 'Adult Health',
    BMI = 3,
    BmiDescription = 'BMI',
    BmiText = 'BMI',
    BreastCancerScreening = 4,
    BreastCancerDescription = 'BreastCancerScreening',
    BreastCancerText = 'Breast Cancer Screening',
    HgbA1c = 5,
    HgbA1cDescription = 'HgbA1c',
    HgbA1cText = 'HgbA1c',
    DiabeticEyeExam = 6,
    DiabeticEyeExamDescription = 'DiabeticEyeExam',
    DiabeticEyeExamText = 'Diabetic Eye Exam',
    MedicationReconciliation = 7,
    MedicationReconciliationDescription = 'MedicationReconciliation',
    MedicationReconciliationText = 'Medication Reconciliation',
    DepressionScreening = 8,
    DepressionScreeningDescription = 'DepressionScreening',
    DepressionScreeningText = 'Depression Screening',
    DepressionFollowUp = 9,
    DepressionFollowUpDescription = 'DepressionFollowUp',
    DepressionFollowUpText = 'Depression FollowUp',
    LipidForBhDx = 10,
    LipidForBhDxDescription = 'LipidForBhDx',
    LipidForBhDxText = 'Lipid For BhDx',
    HgbA1cForBhDx = 11,
    HgbA1cForBhDxDescription = 'HgbA1cForBhDx',
    HgbA1cForBhDxText = 'HgbA1c For BhDx',
}
