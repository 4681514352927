import React, { useEffect, useState } from 'react';
import GoalsAndReports from './GoalsAndReports/GoalsAndReports';
import OnboarderStatuses from './OnboarderStatuses/OnboarderStatuses';
import MembersStatusPage from '../Members/MembersStatusPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchStatusAwaitingApprovalCount } from '../../store/members.slice';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { fetchPopulations } from '../../store/adminOnboarding.slice';
import { getOnboarderSettings } from '../../store/onboardingWorkflow.slice';

const AdminOnboardingPanel = (props) => {
    const styles = {
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: useTheme().spacing(2),
            textAlign: 'center',
            color: useTheme().palette.text.primary,
        },
        title: {
            margin: useTheme().spacing(4, 0, 2),
        },
    };

    const dispatch = useDispatch();

    // states
    const [tab, setTab] = useState<number>(0);
    const { statusAwaitingApprovalCount } = useSelector((state: RootState) => state.members);

    // handlers
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        dispatch(fetchStatusAwaitingApprovalCount());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getOnboarderSettings());
    }, [dispatch]);

    // dynamic content
    let panelContent = undefined;
    switch (tab) {
        case 0: {
            panelContent = <GoalsAndReports />;
            break;
        }
        case 1: {
            panelContent = <OnboarderStatuses viewing={tab === 1} />;
            break;
        }
        case 2: {
            panelContent = (
                <Box style={{ height: 'calc(100vh - 100px)' }}>
                    <MembersStatusPage />
                </Box>
            );
            break;
        }
        default: {
            panelContent = <GoalsAndReports />;
        }
    }

    useEffect(() => {
        dispatch(fetchPopulations());
    }, [dispatch]);

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // render
    return (
        <Paper sx={styles.paper}>
            <Tabs
                variant="fullWidth"
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                centered
            >
                <Tab label="Goals and Reports" />
                <Tab label="Onboarder Statuses" />
                <Tab icon={statusAwaitingApprovalCount > 0 ? <NotificationImportant color="error" /> : null} iconPosition="end" label="Members Statuses" />
            </Tabs>
            {panelContent}
        </Paper>
    );
};

export default AdminOnboardingPanel;
