import BaseEntity from '../BaseEntity.model';

export default interface IOnboarderSettings extends BaseEntity {
    DefaultCallsGoal: number | null;
    DefaultListLength: number | null;
    OnboarderId?: string;
    DefaultAssignmentFrequency: number;
    DefaultFuhfumAssignmentFrequency: number;
    DefaultMaxAssignments: number;
    DefaultFuhfumMaxAssignments: number;
}

export class OnboarderSettings implements IOnboarderSettings {
    Id: string;
    DefaultCallsGoal: number | null;
    DefaultListLength: number | null;
    OnboarderId?: string;
    DefaultAssignmentFrequency: number;
    DefaultFuhfumAssignmentFrequency: number;
    DefaultMaxAssignments: number;
    DefaultFuhfumMaxAssignments: number;

    constructor() {
        this.Id = '';
        this.DefaultCallsGoal = 100;
        this.DefaultListLength = 10;
        this.DefaultAssignmentFrequency = 5;
        this.DefaultFuhfumAssignmentFrequency = 5;
        this.DefaultMaxAssignments = 3;
        this.DefaultFuhfumMaxAssignments = 3;
    }
}
