import { ContactTimeEnum } from '../../Enum/ContactTimeEnum';
import { MemberStatusEnum } from '../../Enum/MemberStatusEnum';
import BaseEntity from '../BaseEntity.model';
import { DocumentOnboarding } from '../DocumentOnboardings/DocumentOnboarding.model';
import { Phone } from '../Shared/Phone.model';
import ActivityNote from './OnboarderNote.model';
import Address from '../Shared/Address.model';
import { AppointmentStatusEnum } from '../../Enum/AppointmentStatusEnum';

export default interface ICallItem extends BaseEntity {
    ActivityNotes: ActivityNote[];
    Address: string;
    Address2: string;
    AHCCCSID: string;
    AssignedOnboarderId: string;
    CallAttempted: boolean;
    CallCount: number;
    CanFinish: boolean;
    CatchAll: any;
    City: string;
    DateOfBirth: string;
    DemographicsRequestCount: number;
    DocumentOnboardingNotes: DocumentOnboarding[];
    Eligibility: string;
    ExpirationDate: Date;
    FirstName: string;
    HealthPlan: string;
    IsDifferMeetingAddress: boolean;
    LastAssignmentDate: Date;
    LastChpAssignmentDateString: string;
    LastContact: Date;
    LastName: string;
    MeetingAddress: Address;
    MemberId: string;
    MemberStatus: MemberStatusEnum;
    OnboardedBy: string;
    Phones: Phone[];
    PopulationId: string;
    PopulationName: string;
    PopulationNumber: number;
    PreferredTime: ContactTimeEnum;
    PreferredTimeVerifiedByMember: boolean;
    State: string;
    TimesAssigned: number;
    TimesAssignedToday: number;
    Zip: string;

    AppointmentId: string;
    AppointmentAddress: string;
    AppointmentEndDateTime: Date;
    AppointmentDateTime: Date;
    AppointmentNotes: string;
    AppointmentReason: string;
    AppointmentStatus: AppointmentStatusEnum;
    AppointmentType: number;
    ProviderNames: string[];
    PreviousMemberCharts: string[];
}

export default class CallItem implements ICallItem {
    ActivityNotes: ActivityNote[];
    Address: string;
    Address2: string;
    AHCCCSID: string;
    AssignedOnboarderId: string;
    CallAttempted: boolean;
    CallCount: number;
    CanFinish: boolean;
    CatchAll: any;
    City: string;
    DateOfBirth: string;
    DemographicsRequestCount: number;
    DocumentOnboardingNotes: DocumentOnboarding[];
    Eligibility: string;
    ExpirationDate: Date | null;
    FirstName: string;
    HealthPlan: string;
    IsDifferMeetingAddress: boolean;
    LastAssignmentDate: Date | null;
    LastChpAssignmentDateString: string;
    LastContact: Date | null;
    LastName: string;
    MeetingAddress: Address | null;
    MemberId: string;
    MemberStatus: MemberStatusEnum | null;
    OnboardedBy: string;
    Phones: Phone[];
    PopulationId: string;
    PopulationName: string;
    PopulationNumber: number | null;
    PreferredTime: ContactTimeEnum;
    PreferredTimeVerifiedByMember: boolean;
    State: string;
    TimesAssigned: number;
    TimesAssignedToday: number;
    Zip: string;

    AppointmentId: string;
    AppointmentAddress: string;
    AppointmentEndDateTime: Date;
    AppointmentNotes: string;
    AppointmentReason: string;
    AppointmentDateTime: Date;
    AppointmentStatus: AppointmentStatusEnum | null;
    AppointmentType: number | null;
    ProviderNames: string[];
    PreviousMemberCharts: string[];

    constructor() {
        this.ActivityNotes = [];
        this.Address = '';
        this.Address2 = '';
        this.AHCCCSID = '';
        this.AssignedOnboarderId = null;
        this.CallAttempted = false;
        this.CallCount = 0;
        this.CanFinish = false;
        this.CatchAll = null;
        this.City = '';
        this.DateOfBirth = '';
        this.DemographicsRequestCount = 0;
        this.DocumentOnboardingNotes = [];
        this.Eligibility = '';
        this.ExpirationDate = null;
        this.FirstName = '';
        this.HealthPlan = '';
        this.IsDifferMeetingAddress = false;
        this.LastAssignmentDate = null;
        this.LastChpAssignmentDateString = '';
        this.LastContact = null;
        this.LastName = '';
        this.MeetingAddress = null;
        this.MemberId = '';
        this.MemberStatus = null;
        this.OnboardedBy = '';
        this.Phones = [];
        this.PopulationId = '';
        this.PopulationName = '';
        this.PopulationNumber = null;
        this.PreferredTime = ContactTimeEnum.None;
        this.PreferredTimeVerifiedByMember = false;
        this.State = '';
        this.TimesAssigned = 0;
        this.TimesAssignedToday = 0;
        this.Zip = '';

        this.AppointmentId = '';
        this.AppointmentAddress = '';
        this.AppointmentNotes = '';
        this.AppointmentReason = '';
        this.AppointmentStatus = null;
        this.AppointmentType = null;
        this.ProviderNames = [];
        this.PreviousMemberCharts = [];
    }
}
