import Authorization from '../../../utils/Authorization';
import AgedRequests from '../DashboardPages/AgedRequests/AgedRequests';
import AppointmentsNotYetConfirmed from '../DashboardPages/AppointmentsNotYetConfirmed/AppointmentsNotYetConfirmed';
import AppointmentsVsEncounters from '../DashboardPages/AppointmentsVsEncounters/AppointmentsVsEncounters';
import CallingPerformance from '../DashboardPages/CallingPerformance/CallingPerformance';
import CancelledAndNotRescheduled from '../DashboardPages/CancelledAndNotRescheduled/CancelledAndNotRescheduled';
import EducationSessionReminder from '../DashboardPages/EducationSessionReminder/EducationSessionReminder';
import ERAdmissions from '../DashboardPages/ERAdmissions/ERAdmissions';
import HighestCostMembers from '../DashboardPages/HighestCostMembers/HighestCostMembers';
import MembersNotScheduledWithBHorNP from '../DashboardPages/MembersNotScheduledWithBHorNP/MembersNotScheduledWithBHorNP';
import MembersWithNoConsentForm from '../DashboardPages/MembersWithNoConsentForm/MembersWithNoConsentForm';
import OnboardingPerformance from '../DashboardPages/OnboardingPerformance/OnboardingPerformance';
import PHQ9 from '../DashboardPages/PHQ9/PHQ9';
import RecentMedicalDocuments from '../DashboardPages/RecentMedicalDocuments/RecentMedicalDocuments';
import ReschedulingOpportunities from '../DashboardPages/ReschedulingOpportunities/ReschedulingOpportunities';
import TelemonitoringOutOfLimitsByUser from '../DashboardPages/TelemonitoringOutOfLimitsByUser/TelemonitoringOutOfLimitsByUser';
import TransitionalCare from '../DashboardPages/TransitionalCare/TransitionalCare';
import MembersWithoutEncounter from '../../Widgets/MembersWithoutEncounter/MembersWithoutEncounter';
import SubmittedClaimSummary from '../../Widgets/SubmittedClaimSummary/SubmittedClaimSummary';
import CleanClaimSummary from '../../Widgets/CleanClaimSummary/CleanClaimSummary';
import CrossDisciplineCareSummary from '../../Widgets/CrossDisciplineCare/CrossDisciplineCareSummary';
import EncountersSummary from '../../Widgets/EncounterSummary/EncounterSummary';
import InpersonVisitsSummary from '../../Widgets/InPersonVisitSummary/InPersonVisitSummary';
import SchedulerSummary from '../../Widgets/SchedulerSummary/SchedulerSummary';
import TeamLevelTcSummaries from '../../Widgets/TeamLevelTcSummary/TeamLevelTcSummary';
import TeamMemberSummary from '../../Widgets/TeamMemberSummary/TeamMemberSummary';
import DocumentTrackingSummaries from '../../Widgets/DocumentTrackingSummary/DocumentTrackingSummary';
import EngagementsSummary from '../../Widgets/EngagementSummary/EngagementSummary';
import TeamInPersonVisitsSummary from '../../Widgets/EngagementSummary/TeamInPersonVisitsSummary';
import IndividualInPersonVisitsSummary from '../../Widgets/EngagementSummary/IndividualInPersonVisitsSummary';
import SchedulingsSummary from '../../Widgets/SchedulingSummary/SchedulingSummary';
import SocialWorkerRequestSummaries from '../../Widgets/SocialWorkerRequestSummary/SocialWorkerRequestSummary';
import GoalSummary from '../../Widgets/CarePlanSummary/GoalSummary';
import CarePlanNotReviewedSummary from '../../Widgets/CarePlanSummary/CarePlanNotReviewedSummary';
import BmiSummaries from '../../HedisMetrics/BMI/BmiSummary';
import BpSummaries from '../../HedisMetrics/BP/BpSummary';
import AdultHealthSummaries from '../../HedisMetrics/AdultHealth/AdultHealthSummary';
import DepressionFollowUpSummaries from '../../HedisMetrics/DepressionFollowUp/DepressionFollowUpSummary';
import DepressionScreeningSummaries from '../../HedisMetrics/DepressionScreening/DepressionScreeningSummary';
import DiabeticEyeExamSummaries from '../../HedisMetrics/DiabeticEyeExam/DiabeticEyeExamSummary';
import HgbA1CSummaries from '../../HedisMetrics/HgbA1C/HgbA1CSummary';
import MedicationReconciliationSummaries from '../../HedisMetrics/MedicationReconciliation/MedicationReconciliationSummary';
import RevenueMetricsSumamry from '../../Widgets/RevenueMetrics/RevenueMetrics';
import { WidgetsEnum } from '../../../utils/constants';
import TotalCost from '../Analytics/TotalCost/TotalCost';
import EDVisit from '../Analytics/EDVisit/EDVisit';
import ACCost from '../Analytics/ACCost/ACCost';
import PHCost from '../Analytics/PHCost/PHCost';
import FFCost from '../Analytics/FFCost/FFCost';
import BhRtcCost from '../Analytics/BhRtcCost/BhRtcCost';
import MembersWithNoPrimaryDiagnosis from '../DashboardPages/MembersWithNoPrimaryDiagnosis/MembersWithNoPrimaryDiagnosis';
import HIEPatientPanelErrors from '../DashboardPages/HIEPatientPanelErrors/HIEPatientPanelErrors';
import MembersMissingGender from '../DashboardPages/MembersMissingGender/MembersMissingGender';
import FuhFumExceptionSummary from '../../Widgets/FuhFumSummary/FuhFumException/FuhFumExceptionSummary';
import FuhfumInProgressSummary from '../../Widgets/FuhFumSummary/FuhFumInProgress/FuhFumInProgressSummary';
import FuhFumUndeterminedSummary from '../../Widgets/FuhFumSummary/FuhFumUndetermined/FuhFumUndeterminedSummary';
import FuhFumQualityMetrics from '../../Widgets/FuhFumSummary/FuhFumQualityMetrics/FuhFumQualityMetrics';
import FuhFumClosedSummary from '../../Widgets/FuhFumSummary/FuhFumClosed/FuhFumClosedSummary';
import SurveySummary from '../../Widgets/SurveySummary/SurveySummary';
import FuhFumFullProgramReferralSummary from '../../Widgets/FuhFumSummary/FuhFumFullProgramReferral/FuhFumFullProgramReferralSummary';
import OnboardCallPerformances from '../DashboardPages/OnboardCallPerformance/OnboardCallPerformance';
import WidgetUsage from '../DashboardPages/WidgetUsage/WidgetUsage';
import FuhFumVisitsReadmissions from '../../Widgets/FuhFumSummary/FuhFumVisitsReadmissions/FuhFumVisitsReadmissions';

const auth = new Authorization();

const showProviders = auth.Role === 'CEO' || auth.Role === 'Central Operation' ? true : false;

export const allWidgetsList = [
    { name: WidgetsEnum.AGED_REQUESTS, component: <AgedRequests /> },
    { name: WidgetsEnum.RECENT_MEDICAL_DOCUMENTS, component: <RecentMedicalDocuments /> },
    { name: WidgetsEnum.MEMBERS_WITH_NO_CONSENT_FORM, component: <MembersWithNoConsentForm /> },
    { name: WidgetsEnum.MEMBERS_MISSING_GENDER, component: <MembersMissingGender /> },
    { name: WidgetsEnum.APPOINTMENTS_VS_ENCOUNTERS, component: <AppointmentsVsEncounters /> },
    { name: WidgetsEnum.HIGHEST_COST_MEMBERS, component: <HighestCostMembers /> },
    {
        name: WidgetsEnum.TELEMONITORING_OUT_OF_LIMITS_BY_USER,
        component: <TelemonitoringOutOfLimitsByUser />,
    },
    {
        name: WidgetsEnum.APPOINTMENTS_NOT_YET_CONFIRMED,
        component: <AppointmentsNotYetConfirmed />,
    },
    { name: WidgetsEnum.CANCELLED_AND_NOT_RESCHEDULED, component: <CancelledAndNotRescheduled /> },
    {
        name: WidgetsEnum.MEMBERS_NOT_SCHEDULED_WITH_BH_OR_NP,
        component: <MembersNotScheduledWithBHorNP />,
    },
    { name: WidgetsEnum.ER_ADMISSIONS, component: <ERAdmissions showProviders={showProviders} providerId={auth.UserId} /> },
    { name: WidgetsEnum.TRANSITIONAL_CARE, component: <TransitionalCare showProviders={showProviders} providerId={auth.UserId} /> },
    { name: WidgetsEnum.PHQ9, component: <PHQ9 /> },
    { name: WidgetsEnum.PATIENT_PANEL_ERRORS, component: <HIEPatientPanelErrors /> },
    { name: WidgetsEnum.ONBOARDING_PERFORMANCE, component: <OnboardingPerformance /> },
    { name: WidgetsEnum.CALLING_PERFORMANCE, component: <CallingPerformance /> },
    { name: WidgetsEnum.RESCHEDULING_OPPORTUNITIES, component: <ReschedulingOpportunities /> },
    { name: WidgetsEnum.EDUCATION_SESSION_REMINDER, component: <EducationSessionReminder /> },
    { name: WidgetsEnum.MEMBERS_WITH_NO_PRIMARY_DIAGNOSIS, component: <MembersWithNoPrimaryDiagnosis /> },
    { name: WidgetsEnum.ONBOARD_CALL_PERFORMANCE, component: <OnboardCallPerformances /> },
    // Analytics Widgets
    { name: WidgetsEnum.MEMBERS_WITHOUT_ENCOUNTER, component: <MembersWithoutEncounter /> },
    { name: WidgetsEnum.SUBMITTED_CLAIM_SUMMARY, component: <SubmittedClaimSummary /> },
    { name: WidgetsEnum.CLEAN_CLAIM_SUMMARY, component: <CleanClaimSummary /> },
    { name: WidgetsEnum.CROSS_DISCIPLINE_CARE_SUMMARY, component: <CrossDisciplineCareSummary /> },
    { name: WidgetsEnum.ENCOUNTERS_SUMMARY, component: <EncountersSummary /> },
    { name: WidgetsEnum.IN_PERSON_VISITS_SUMMARY, component: <InpersonVisitsSummary /> },
    { name: WidgetsEnum.SCHEDULER_SUMMARY, component: <SchedulerSummary /> },
    { name: WidgetsEnum.TEAM_TC_SUMMARY, component: <TeamLevelTcSummaries /> },
    { name: WidgetsEnum.TEAM_MEMBER_SUMMARY, component: <TeamMemberSummary /> },
    { name: WidgetsEnum.DOCUMENT_TRACKING_SUMMARY, component: <DocumentTrackingSummaries /> },
    { name: WidgetsEnum.ENGAGEMENTS_SUMMARY, component: <EngagementsSummary /> },
    { name: WidgetsEnum.TEAM_IN_PERSON_VISITS_SUMMARY, component: <TeamInPersonVisitsSummary /> },
    { name: WidgetsEnum.INDIVIDUAL_IN_PERSON_VISITS_SUMMARY, component: <IndividualInPersonVisitsSummary /> },
    { name: WidgetsEnum.SCHEDULINGS_SUMMARY, component: <SchedulingsSummary /> },
    { name: WidgetsEnum.SOCIAL_WORKER_REQUEST_SUMMARY, component: <SocialWorkerRequestSummaries /> },
    { name: WidgetsEnum.MEMBERS_WITH_GOALS_NOT_MET, component: <GoalSummary /> },
    { name: WidgetsEnum.CARE_PLAN_NOT_REVIEWED_SUMMARY, component: <CarePlanNotReviewedSummary /> },
    { name: WidgetsEnum.REVENUE_METRICS, component: <RevenueMetricsSumamry /> },
    { name: WidgetsEnum.FUHFUM_EXCEPTION_SUMMARY, component: <FuhFumExceptionSummary /> },
    { name: WidgetsEnum.FUHFUM_INPROGRESS_SUMMARY, component: <FuhfumInProgressSummary /> },
    { name: WidgetsEnum.FUHFUM_UNDETERMINED_SUMMARY, component: <FuhFumUndeterminedSummary /> },
    { name: WidgetsEnum.FUHFUM_QUALITY_METRICS, component: <FuhFumQualityMetrics /> },
    { name: WidgetsEnum.FUHFUM_CLOSED_SUMMARY, component: <FuhFumClosedSummary /> },
    { name: WidgetsEnum.FUHFUM_FULL_PROGRAM_REFERRAL_SUMMARY, component: <FuhFumFullProgramReferralSummary /> },
    { name: WidgetsEnum.FUHFUM_VISIT_READMISSION_SUMMARY, component: <FuhFumVisitsReadmissions /> },
    // Hedis Metrics Widgets
    { name: WidgetsEnum.BMI_SUMMARY, component: <BmiSummaries /> },
    { name: WidgetsEnum.BP_SUMMARY, component: <BpSummaries></BpSummaries> },
    { name: WidgetsEnum.ADULT_HEALTH_SUMMARY, component: <AdultHealthSummaries /> },
    { name: WidgetsEnum.DEPRESSION_FOLLOW_UP_SUMMARY, component: <DepressionFollowUpSummaries /> },
    { name: WidgetsEnum.DEPRESSION_SCREENING_SUMMARY, component: <DepressionScreeningSummaries /> },
    { name: WidgetsEnum.DIABETIC_EYE_EXAM, component: <DiabeticEyeExamSummaries /> },
    { name: WidgetsEnum.HGB_A1C_SUMMARY, component: <HgbA1CSummaries /> },
    { name: WidgetsEnum.MEDICATION_RECONCILIATION_SUMMARY, component: <MedicationReconciliationSummaries /> },
    // Analytics
    { name: WidgetsEnum.TOTAL_COST, component: <TotalCost /> },
    { name: WidgetsEnum.EMERGENCY_DEPARTMENT_VISITS, component: <EDVisit /> },
    { name: WidgetsEnum.ACUTE_CARE_COSTS, component: <ACCost /> },
    { name: WidgetsEnum.PHARMACEUTICAL_COSTS, component: <PHCost /> },
    { name: WidgetsEnum.ACUTE_BH_COSTS, component: <FFCost /> },
    { name: WidgetsEnum.BH_RTC_RF_COSTS, component: <BhRtcCost /> },
    { name: WidgetsEnum.SURVEY_SUMMARY, component: <SurveySummary /> },
    { name: WidgetsEnum.WIDGET_USAGE, component: <WidgetUsage /> },
];
