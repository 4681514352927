import React from 'react';
import WidgetBox from '../WidgetBox';
import CallItem from '../../../Models/OfficeOnboarding/CallItem.model';
import { Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearOnboarderCallList } from '../../../store/adminOnboarding.slice';
import { RootState } from '../../../reducers';

const CurrentCallList = () => {
    const { selectedOnboarder } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const dispatch = useDispatch();
    let callRows = undefined;
    if (selectedOnboarder.CallList) {
        callRows = selectedOnboarder.CallList.map((item: CallItem) => (
            <Grid item margin={2}>
                <Paper elevation={2}>
                    <Grid container p={2} spacing={2} alignItems="flex-start" justifyContent="space-evenly" direction="column">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                disabled
                                value={item.FirstName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                disabled
                                value={item.LastName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Population"
                                disabled
                                value={item.PopulationName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Zip Code"
                                disabled
                                value={item.Zip}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        minHeight: '15px',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Times Assigned"
                                disabled
                                value={item.TimesAssigned}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        minHeight: '15px',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Times Assigned Today"
                                disabled
                                value={item.TimesAssignedToday}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        minHeight: '15px',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ));
    }

    const clearCallListHandler = () => {
        const assignedOnboarderId = selectedOnboarder.CallList[0]?.AssignedOnboarderId;
        dispatch(clearOnboarderCallList(assignedOnboarderId));
    };

    return (
        <WidgetBox>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={'center'} my={5} px={5}>
                <Stack direction={'row'} spacing={2}>
                    <Typography variant="h6">{selectedOnboarder.Username} Current Call List:</Typography>
                    <Typography variant="h6" fontWeight={'bold'}>
                        {callRows.length} Left
                    </Typography>
                </Stack>
                <Button variant="contained" color="primary" onClick={clearCallListHandler} disabled={selectedOnboarder.CallList.length === 0} sx={{ mb: 2 }}>
                    Clear Call List
                </Button>
            </Stack>
            <Grid direction="row" alignItems="center" justifyContent="center" container spacing={1}>
                {React.Children.toArray(callRows)}
            </Grid>
        </WidgetBox>
    );
};

export default CurrentCallList;
