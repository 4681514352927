import { Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setSelectedTab } from '../../store/claimsManagement.slice';
import AdminTeams from './Teams/AdminTeams';
import TeamOptimizer from './TeamOptimizer/TeamOptimizer';

const TeamsPage = (props) => {
    const { selectedTab } = useSelector((state: RootState) => state.claimsManagement);
    const dispatch = useDispatch();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(setSelectedTab(newValue));
    };

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBody = () => {
        return (
            <>
                <Tabs
                    variant="fullWidth"
                    value={selectedTab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleTabChange}
                    aria-label="disabled tabs example"
                    centered
                >
                    <Tab label="Team Management" />
                    <Tab label="Team Optimizer" />
                </Tabs>
                {selectedTab === 0 && <AdminTeams />}
                {selectedTab === 1 && <TeamOptimizer />}
            </>
        );
    };

    return getBody();
};

export default TeamsPage;
