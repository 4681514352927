import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const memberHedisReport = createSlice({
    name: 'memberHedisReport',
    initialState: {
        memberHedisData: [],
    },
    reducers: {
        _setMemberHedisData(state, action) {
            state.memberHedisData = action.payload;
        },
    },
});

const { _setMemberHedisData } = memberHedisReport.actions;

export const fetchMemberHedisReport =
    (start: Date, end: Date, healthPlan: string, team: string, hedisTypes: string[], hedisMetricStatus: string, includeInActiveTeams: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberHedisData([]));
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/memberhedisreport/getmemberhedisreport?start=${dateFormatter(start)}&end=${dateFormatter(end)}&healthPlanId=${healthPlan}&teamId=${
                team !== '-1' ? team : ''
            }&hedisTypes=${hedisTypes.join(',')}&hedisMetricStatus=${hedisMetricStatus}&includeInActiveTeams=${includeInActiveTeams}`
        );
        dispatch(setIsLoading(false));
        dispatch(_setMemberHedisData([]));
        dispatch(_setMemberHedisData(response.data));
    };

export const exportMemberHedisReport =
    (start: Date, end: Date, healthPlan: string, team: string, hedisTypes: string[], hedisMetricStatus: string, includeInActiveTeams: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/memberhedisreport/exportmemberhedisreport?start=${dateFormatter(start)}&end=${dateFormatter(end)}&healthPlanId=${healthPlan}&teamId=${
                team !== '-1' ? team : ''
            }&hedisTypes=${hedisTypes.join(',')}&hedisMetricStatus=${hedisMetricStatus}&includeInActiveTeams=${includeInActiveTeams}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberHedisReport.reducer;
