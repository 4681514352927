import { Button, Dialog, Grid, Stack, Tooltip } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import phoneFormat from '../../../utils/phoneFormat';
import CancelIcon from '@mui/icons-material/Cancel';
import MemberSearch from '../../Shared/MemberSearch';
import { Typography } from '@mui/material';

const ViewTeams = (props) => {
    const { currentTeam } = props;

    const membersColumns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 200,
            flex: 1.5,
        },
        {
            field: 'MobilePhone',
            headerName: 'Mobile Phone',
            minWidth: 150,
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.MobilePhone)}`,
        },
        {
            field: 'RiskLevel',
            headerName: 'Risk Level',
            minWidth: 125,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => props.removeMemberFromTeam(params.id, props.currentTeam.Id)}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
            <Grid container p={3}>
                <Grid item xs={8}>
                    <Stack direction="row" alignItems="flex-end">
                        <Typography style={{ marginRight: '16px' }} variant="h4">
                            {currentTeam.Name.toUpperCase()}
                        </Typography>
                        <Typography variant="body1">{currentTeam.TypeName}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={4} textAlign="right">
                    <Button variant="contained" style={{ minWidth: '120px' }} onClick={props.onClose}>
                        Close
                    </Button>
                </Grid>

                <Grid xs={12} item>
                    <Stack direction={'row'} sx={{ width: '100%', marginY: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <MemberSearch
                            rowData={props.memberSearch.rowData}
                            allMembers={props.teamMembers}
                            setRowData={props.memberSearch.setRowData}
                            memberSearchValue={props.memberSearch.memberSearchValue}
                            setMemberSearchValue={props.memberSearch.setMemberSearchValue}
                        />
                        <Button variant="contained" className="button-120" onClick={props.addMember}>
                            Add Member
                        </Button>
                    </Stack>
                    <div style={{ height: '58vh', width: '100%' }}>
                        <DataGrid
                            rows={props.rowData}
                            columns={membersColumns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ViewTeams;
