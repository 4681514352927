import { TextField, Autocomplete, Paper, Grid, Typography } from '@mui/material';
import IOnboarderStatus from '../../../../Models/OfficeOnboarding/OnboarderStatus.model';
import StatusSaveButton from '../../Buttons/StatusSaveButton';
import { OnboarderSettings } from '../../../../Models/AdminOnboarding/OnboarderSettings.model';

interface OnboarderStatusControlBarProps {
    autoCompleteOnChange: Function;
    autocompleteOptions: IOnboarderStatus[];
    autocompleteInputValue: string;
    autocompleteHelperText: string;
    callsGoalFieldValue: number | null;
    callsGoalFieldLabel: string;
    callsGoalFieldHelperText: string;
    callsGoalFieldOnChange: Function;
    callsListFieldValue: number | null;
    callsListFieldLabel: string;
    callsListFieldHelperText: string;
    callsListFieldOnChange: Function;
    saving: boolean;
    doSaveButtonClick: Function;
    saveButtonLabel: string;
    saveButtonDisabled: boolean;
    assignmentFrequencyValue: number;
    maxAssignmentsValue: number;
    onboarderSettings: OnboarderSettings;
    onAssignmentFrequencyChange: Function;
    onMaxAssignmentsChange: Function;
    onFuhfumAssignmentFrequencyChange: Function;
    onFuhfumMaxAssignmentsChange: Function;
    fuhfumAssignmentFrequencyValue: number;
    fuhfumMaxAssignmentsValue: number;
}

const OnboarderStatusControlBar = (props: OnboarderStatusControlBarProps) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 5, mt: 2 }}>
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1} rowGap={2}>
                {props.autocompleteInputValue === '' ? (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Typography variant="h6">Edit Default Settings</Typography>
                    </Grid>
                ) : null}
                <Grid item>
                    <Autocomplete
                        onChange={(event, value) => {
                            props.autoCompleteOnChange(event, value);
                        }}
                        fullWidth
                        sx={{ minWidth: 250 }}
                        options={props.autocompleteOptions}
                        inputValue={props.autocompleteInputValue}
                        isOptionEqualToValue={(option, value) => {
                            return option.Id === value.Id;
                        }}
                        size="small"
                        getOptionLabel={(option: any) => option.Username}
                        renderInput={(params) => (
                            <TextField {...params} label="Select Specific Onboarder" variant="outlined" helperText={props.autocompleteHelperText} fullWidth />
                        )}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={props.callsGoalFieldLabel}
                        helperText={props.callsGoalFieldHelperText}
                        type="number"
                        variant="outlined"
                        value={props.callsGoalFieldValue}
                        onChange={(event) => {
                            props.callsGoalFieldOnChange(event);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={props.callsListFieldLabel}
                        helperText={props.callsListFieldHelperText}
                        type="number"
                        variant="outlined"
                        value={props.callsListFieldValue}
                        onChange={(event) => {
                            props.callsListFieldOnChange(event);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        disabled={props.autocompleteInputValue !== ''}
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={'Hours Between Assignments'}
                        name="AssignmentFrequency"
                        type="number"
                        value={props.assignmentFrequencyValue}
                        onChange={(event: any) => {
                            props.onAssignmentFrequencyChange(event);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        disabled={props.autocompleteInputValue !== ''}
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={'Max per Day'}
                        name="MaxAssignments"
                        type="number"
                        value={props.maxAssignmentsValue}
                        onChange={(event: any) => {
                            props.onMaxAssignmentsChange(event);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        disabled={props.autocompleteInputValue !== ''}
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={'FUHFUM Hours Between Assignments'}
                        // name="AssignmentFrequency"
                        type="number"
                        value={props.fuhfumAssignmentFrequencyValue || 0}
                        onChange={(event: any) => {
                            props.onFuhfumAssignmentFrequencyChange(event);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        disabled={props.autocompleteInputValue !== ''}
                        inputProps={{
                            style: { textAlign: 'center', width: '150px' },
                        }}
                        size="small"
                        label={'FUHFUM Max per Day'}
                        // name="MaxAssignments"
                        type="number"
                        value={props.fuhfumMaxAssignmentsValue || 0}
                        onChange={(event: any) => {
                            props.onFuhfumMaxAssignmentsChange(event);
                        }}
                    />
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                    <StatusSaveButton
                        saving={props.saving}
                        onClick={() => {
                            props.doSaveButtonClick();
                        }}
                        label={props.saveButtonLabel}
                        disabled={props.saveButtonDisabled}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default OnboarderStatusControlBar;
