import { Grid, Paper, Typography, Table, TableBody, TableHead, TableRow, TableContainer, Tabs, Tab, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { isDefaultDate } from '../../../../utils/common';

import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { fetchFuhFumQualityMetrics, exportFuhFumQualityMetrics } from '../../../Widgets/WidgetService';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { parentUrl } from '../../../../utils/constants';
import '../../Widgets.css';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FuhFumQualityMetricsDetail = () => {
    const history = useNavigate();
    const [qualityMetrics, setQualityMetrics] = useState<any>({});
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [filteredList, setFilteredList] = useState([]);
    const [referredList, setReferredList] = useState([]);
    const {
        qualityMetricsDateFilter,
        setQualityMetricsDateFilter,
        qualityMetricsFromDate,
        setQualityMetricsFromDate,
        qualityMetricsToDate,
        setQualityMetricsToDate,
        qualityMetricsTab,
    } = useWidgetContext();

    const getFuhFumSummary = async () => {
        if (qualityMetricsDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(qualityMetricsFromDate) && Boolean(qualityMetricsToDate))) {
            setIsFetched(false);
            const changeFilter = async () => {
                let dateFrom =
                    Boolean(qualityMetricsFromDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(qualityMetricsFromDate).toISOString()
                        : '';
                let dateTo =
                    Boolean(qualityMetricsToDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(qualityMetricsToDate).toISOString()
                        : '';

                const metrics = await fetchFuhFumQualityMetrics(qualityMetricsDateFilter, dateFrom, dateTo, true);
                setQualityMetrics(metrics);
                setCurrentTab(qualityMetricsTab);
                filterData(qualityMetricsTab, metrics);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (qualityMetricsDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(qualityMetricsFromDate) && Boolean(qualityMetricsToDate)) {
            setQualityMetricsFromDate(null);
            setQualityMetricsToDate(null);
        }
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualityMetricsDateFilter, qualityMetricsFromDate, qualityMetricsToDate]);

    const changeFromDate = (date: any) => {
        setQualityMetricsFromDate(date);
    };

    const changeToDate = (date: any) => {
        setQualityMetricsToDate(date);
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const filterData = (tab: any, list: any) => {
        let filterResponse = [];
        switch (tab) {
            case 1: {
                filterResponse = list.InProgressMembers;
                setReferredList(list.ReferredMembers);
                break;
            }
            case 2: {
                filterResponse = list.MembersWithLicensedVisitsWithin7Days;
                break;
            }
            case 3: {
                filterResponse = list.MembersWithLicensedVisitsWithin14Days;
                break;
            }
            case 4: {
                filterResponse = list.MembersWithLicensedVisitsWithin30Days;
                break;
            }
            case 5: {
                filterResponse = list.ReadmittedMembersWithin30Days;
                break;
            }
            case 6: {
                filterResponse = list.EnrolledInFullProgramMembers;
                break;
            }
        }
        setFilteredList(filterResponse);
        setCurrentTab(tab);
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        filterData(newValue, qualityMetrics);
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let dateFrom =
            Boolean(qualityMetricsFromDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom ? new Date(qualityMetricsFromDate).toISOString() : '';
        let dateTo =
            Boolean(qualityMetricsToDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom ? new Date(qualityMetricsToDate).toISOString() : '';

        const data = await exportFuhFumQualityMetrics(qualityMetricsDateFilter, dateFrom, dateTo);
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumQualityMetrics - ${dateInfo}.xlsx`);
        link.click();
        link.remove();
        setDownLoading(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1} alignItems={'center'} display={'flex'}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            FUHFUM Quality Metrics
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={qualityMetricsDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setQualityMetricsDateFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(qualityMetricsFromDate) ? qualityMetricsFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(qualityMetricsToDate) ? qualityMetricsToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom ? 6.5 : 9.5}></Grid>
                    <Grid item xs={1}>
                        <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12} display={'flex'}>
                                    <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={currentTab} onChange={handleAlertsTabChange}>
                                        <Tab value={1} label="In-progress vs Referred" />
                                        <Tab value={2} label="Licensed Visits Within 7 Days" />
                                        <Tab value={3} label="Licensed Visits Within 14 Days" />
                                        <Tab value={4} label="Licensed Visits Within 30 Days" />
                                        <Tab value={5} label="Readmission Within 30 Days" />
                                        <Tab value={6} label="Enrolled in Full Program" />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={currentTab === 1 ? 6 : 12} textAlign="center">
                                    {currentTab === 1 ? (
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            In-Progress Members ({filteredList.length})
                                        </Typography>
                                    ) : null}
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell>Last Name</StyledTableCell>
                                                    <StyledTableCell>First Name</StyledTableCell>
                                                    <StyledTableCell>DOB</StyledTableCell>
                                                    <StyledTableCell>Gender</StyledTableCell>
                                                    <StyledTableCell>Address</StyledTableCell>
                                                    <StyledTableCell>Referral Date</StyledTableCell>
                                                    <StyledTableCell>Admission Date</StyledTableCell>
                                                    <StyledTableCell>Facility</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredList.length ? (
                                                    filteredList.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.LastName}</StyledTableCell>
                                                            <StyledTableCell>{row.FirstName}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.DOB) ? moment(new Date(row.DOB)).format('MM/DD/yyyy') : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Gender}</StyledTableCell>
                                                            <StyledTableCell>{row.Address}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.ReferralDate)
                                                                    ? moment(new Date(row.ReferralDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.AdmitDate) ? moment(new Date(row.AdmitDate)).format('MM/DD/yyyy') : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Facility}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={9}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {currentTab === 1 ? (
                                    <Grid item xs={6} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Referred Members ({referredList.length})
                                        </Typography>
                                        <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                            <Table aria-label="customized table" stickyHeader>
                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell>#</StyledTableCell>
                                                        <StyledTableCell>Last Name</StyledTableCell>
                                                        <StyledTableCell>First Name</StyledTableCell>
                                                        <StyledTableCell>DOB</StyledTableCell>
                                                        <StyledTableCell>Gender</StyledTableCell>
                                                        <StyledTableCell>Address</StyledTableCell>
                                                        <StyledTableCell>Referral Date</StyledTableCell>
                                                        <StyledTableCell>Admission Date</StyledTableCell>
                                                        <StyledTableCell>Facility</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {referredList.length ? (
                                                        referredList.map((row, index) => (
                                                            <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell>{row.LastName}</StyledTableCell>
                                                                <StyledTableCell>{row.FirstName}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    {!isDefaultDate(row.DOB) ? moment(new Date(row.DOB)).format('MM/DD/yyyy') : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell>{row.Gender}</StyledTableCell>
                                                                <StyledTableCell>{row.Address}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    {!isDefaultDate(row.ReferralDate)
                                                                        ? moment(new Date(row.ReferralDate)).format('MM/DD/yyyy')
                                                                        : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {!isDefaultDate(row.AdmitDate) ? moment(new Date(row.AdmitDate)).format('MM/DD/yyyy') : '-'}
                                                                </StyledTableCell>
                                                                <StyledTableCell>{row.Facility}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <StyledTableRow>
                                                            <StyledTableCell component="th" scope="row" colSpan={9}>
                                                                No data.
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                ) : null}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FuhFumQualityMetricsDetail;
