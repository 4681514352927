import { Paper, Divider, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { TeamIcons } from '../../Maps/TeamOptimizerMap';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { saveMaxTeamSize } from '../../../store/teams.slice';
import { useDispatch } from 'react-redux';

const TeamKey = ({ team, index }) => {
    const dispatch = useDispatch();
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [maxTeamSize, setMaxTeamSize] = useState<number>(team.MaxTeamSize);

    const teamEditMaxTeamSizeModal = () => {
        return (
            <Dialog disableScrollLock disableRestoreFocus disableAutoFocus disableEnforceFocus open={showEdit} keepMounted onClose={() => setShowEdit(false)}>
                <DialogTitle>Edit Max Team Size - {team.Name}</DialogTitle>
                <DialogContent dividers sx={{ width: '400px' }}>
                    <FormControl fullWidth>
                        <TextField
                            required
                            id="maxTeamSize"
                            label="Max Team Size"
                            name="MaxTeamSize"
                            variant="outlined"
                            type="number"
                            value={maxTeamSize}
                            onChange={(e) => {
                                setMaxTeamSize(parseInt(e.target.value));
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            dispatch(saveMaxTeamSize(maxTeamSize, team.Id));
                            setShowEdit(false);
                        }}
                    >
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={() => setShowEdit(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
            {teamEditMaxTeamSizeModal()}
            <Divider>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h6">{team.Name}</Typography>
                    {<img style={{ marginLeft: 'auto' }} src={TeamIcons[index]} alt="teamIcon" />}
                </Stack>
                <Divider style={{ marginTop: '8px' }} />
                <Stack direction="row" spacing={2}>
                    <Typography>Member Count: {team.MemberCount}</Typography>
                    <Typography>Max Size: {team.MaxTeamSize}</Typography>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => setShowEdit(true)} />
                </Stack>
            </Divider>
        </Paper>
    );
};

export default TeamKey;
