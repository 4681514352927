// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Stack, Typography } from '@mui/material';
import BlackSquare from '../../assets/images/mapicons/black-square.png';
import BlueCircle from '../../assets/images/mapicons/blue-circle.png';
import PurpleDiamond from '../../assets/images/mapicons/purple-diamond.png';
import PinkPentagon from '../../assets/images/mapicons/pink-pentagon.png';
import RedHeart from '../../assets/images/mapicons/red-heart.png';
import YellowTriangle from '../../assets/images/mapicons/yellow-triangle.png';
import GreenHexagon from '../../assets/images/mapicons/green-hexagon.png';
import BlackSquareSM from '../../assets/images/mapicons/black-square-sm.png';
import BlueCircleSM from '../../assets/images/mapicons/blue-circle-sm.png';
import PurpleDiamondSM from '../../assets/images/mapicons/purple-diamond-sm.png';
import PinkPentagonSM from '../../assets/images/mapicons/pink-pentagon-sm.png';
import RedHeartSM from '../../assets/images/mapicons/red-heart-sm.png';
import YellowTriangleSM from '../../assets/images/mapicons/yellow-triangle-sm.png';
import GreenHexagonSM from '../../assets/images/mapicons/green-hexagon-sm.png';
import SelectedIcon from '../../assets/images/mapicons/selected.png';

export const TeamIcons = [BlackSquare, BlueCircle, PurpleDiamond, PinkPentagon, RedHeart, YellowTriangle, GreenHexagon];
export const MemberIcons = [BlackSquareSM, BlueCircleSM, PurpleDiamondSM, PinkPentagonSM, RedHeartSM, YellowTriangleSM, GreenHexagonSM];

const TeamOptimizerMap = ({ selectedLat, selectedLon }) => {
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [showName, setShowName] = useState('');

    const [defaultCenter, setDefaultCenter] = useState({
        lat: 33.302947,
        lng: -111.803276,
    });
    const { mapAPIKey } = useSelector((state: RootState) => state.shared);
    const { optimizedTeamResults } = useSelector((state: RootState) => state.teams);

    const mapStyles = {
        height: 'calc(100vh - 350px)',
        width: '100%',
    };

    const mouseOver = (show: boolean, name: string) => {
        setShowName(name);
        setShowInfoWindow(show);
    };

    useEffect(() => {
        console.log('optimizedTeamResults', optimizedTeamResults);
        if (optimizedTeamResults && optimizedTeamResults.length > 0) {
            var firstTeam = optimizedTeamResults[0].Team;
            if (firstTeam.Address) {
                setDefaultCenter({ lat: firstTeam.Address?.Lat, lng: firstTeam.Address?.Lon });
            }
        }
    }, [optimizedTeamResults]);

    useEffect(() => {
        if (selectedLat !== 0 && selectedLon !== 0) {
            setDefaultCenter({ lat: selectedLat, lng: selectedLon });
            for (let teamResult of optimizedTeamResults) {
                for (let memberResult of teamResult.MemberResults) {
                    if (memberResult.Lat === selectedLat && memberResult.Lon === selectedLon) {
                        mouseOver(true, memberResult.Member.AhcccsId);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLat, selectedLon]);

    return (
        // @ts-ignore
        <LoadScript googleMapsApiKey={mapAPIKey}>
            <GoogleMap mapContainerStyle={mapStyles} zoom={11} center={defaultCenter}>
                {optimizedTeamResults?.map((teamResult, indexTeam) => {
                    return teamResult.MemberResults.map((memberResult, indexMember) => {
                        return (
                            <Marker
                                onMouseOver={() => {
                                    mouseOver(true, memberResult.Member.AhcccsId);
                                }}
                                onMouseOut={() => {
                                    mouseOver(false, memberResult.Member.AhcccsId);
                                }}
                                zIndex={indexMember}
                                opacity={0.8}
                                label=""
                                key={memberResult.Member.AhcccsId}
                                position={{ lat: memberResult.Lat, lng: memberResult.Lon }}
                                icon={memberResult.Lat === selectedLat && memberResult.Lon === selectedLon ? SelectedIcon : MemberIcons[indexTeam]}
                            >
                                {showInfoWindow && showName === memberResult.Member.AhcccsId && (
                                    <InfoWindow>
                                        <>
                                            <Stack spacing={2} direction={'row'}>
                                                <Typography style={{ fontWeight: 'bold' }}>{memberResult.Member.AhcccsId}</Typography>
                                                <Typography style={{ fontWeight: 'bold' }}>{memberResult.Member.Name}</Typography>
                                            </Stack>
                                            <hr />
                                            <Typography>{memberResult.Member.Address}</Typography>
                                        </>
                                    </InfoWindow>
                                )}
                            </Marker>
                        );
                    });
                })}
                {optimizedTeamResults?.map((teamResult, indexTeam) => {
                    return (
                        // @ts-ignore
                        <Marker
                            onMouseOver={() => {
                                mouseOver(true, teamResult.Team.Name);
                            }}
                            onMouseOut={() => {
                                mouseOver(false, teamResult.Team.Name);
                            }}
                            zIndex={-1}
                            opacity={0.8}
                            label={{ text: 'TM', color: 'white', fontWeight: 'bold' }}
                            key={indexTeam}
                            style={{ color: 'purple' }}
                            position={{ lat: teamResult.Team.Address?.Lat, lng: teamResult.Team.Address?.Lon }}
                            icon={TeamIcons[indexTeam]}
                        >
                            {
                                // @ts-ignore
                                showInfoWindow && showName === teamResult.Team.Name ? (
                                    <InfoWindow>
                                        <>
                                            <Typography style={{ fontWeight: 'bold' }}>{teamResult.Team.Name}</Typography>
                                        </>
                                    </InfoWindow>
                                ) : null
                            }
                        </Marker>
                    );
                })}
            </GoogleMap>
        </LoadScript>
    );
};

export default TeamOptimizerMap;
